/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-02-06 15:21:14
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:49:36
 * @Description: 统计分析
 */

import View from '@/components/view';

const routes = [
    {
        path: 'statistics-analyze',
        component: View,
        meta: {
            title: '统计分析'
        },
        children: [
            {
                path: 'catalogue-analyze',
                component: () => import(/* webpackChunkName: "gl-catalogue-analyze" */ '@/pages/aup/manage-org/proj-inner/statistics-analyze/catalogue-analyze'),
                meta: {
                    title: '采购目录分析'
                }
            },
            {
                path: 'product-analyze',
                component: () => import(/* webpackChunkName: "gl-product-analyze" */ '@/pages/aup/manage-org/proj-inner/statistics-analyze/product-analyze'),
                meta: {
                    title: '产品分析'
                }
            },
            {
                path: 'result-analyze',
                component: () => import(/* webpackChunkName: "gl-result-analyze" */ '@/pages/aup/manage-org/proj-inner/statistics-analyze/result-analyze'),
                meta: {
                    title: '挂网结果分析'
                }
            }
           
        ]
    }
];

export default routes;
