/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-14 15:39:35
 * @Last Modified by: hejk
 * @Last Modified time: 2019-12-26 14:25:02
 * @Description: 配置
 */
import { deepCopy, deepMergeObj } from '@/utils/utils';
import search from './common-search';
import cols from './common-cols';
import forms from './common-forms';

const mergeConfig = (config = {}, source) => (deepMergeObj(source, config));
export default {
    // 列
    ...cols,
    // 搜索
    ...search,
    // 表单
    ...forms,
    // 获得列表
    getCol(arr) {
        return this.getArr(arr, this.cols);
    },
    // 获取筛选项
    getSearch(arr) {
        const allSearch = this.getArr(arr, this.search);
        return allSearch;
    },
    // 获取表单项
    getForm(arr) {
        return this.getArr(arr, this.forms);
    },
    getArr(arr, res) {
        const currentArr = [];
        let targets = arr;
        if (typeof arr === 'string') {
            targets = arr.split(',');
        }
        const data = deepCopy(res);
        targets.forEach(key => {
            let item = '';
            if (typeof key === 'string') {
                item = data[key];
            } else {
                const newKey = key.fieldName;
                const val = key || {};
                item = data[newKey];
                if (item) {
                    item = mergeConfig(val, item);
                }
            }
            if (item) currentArr.push(item);
        });
        return currentArr;
    }
};
