import devConfig from './config.dev';
import prodConfig from './config.prod';
import preConfig from './config.pre';
import testConfig from './config.test';
import baseConfig from './config.base';

const env = process.env.CM_ENV || process.env.NODE_ENV;
const config = env === 'development' ? devConfig : (env === 'production' ? prodConfig : (env === 'pre' ? preConfig : testConfig));
config.baseOss = {
    ...config.baseOss,
    '/web/rpup': `${config.baseUrl}/hsaRpup/tps-rpup`
};

export default Object.assign(baseConfig, config);
