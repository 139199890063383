/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-21 17:40:48
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:34:06
 * @Description: 限价公示
 */
import View from '@/components/view';

const index = () => import(/* webpackChunkName: "gl-price-limit-pub" */ '@/pages/aup/manage-org/proj-inner/price-limit-pub/index');


const routes = [
    {
        path: 'price-limit-pub',
        component: View,
        meta: {
            title: '限价公示'
        },
        children: [
            {
                path: 'index',
                component: index,
                meta: {
                    title: '产品限价管理'
                }
            }
        ]
    }
];

export default routes;
