<template>
    <div v-if="currentVisible"
         class="PDF_dialog_box">
        <div class="PDF_dialog_close"
             @click="close">
            关闭
        </div>

        <iframe id="framework"
                title="framework"
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                :src="pdfUrl" />
    </div>
</template>
<script>
export default {
    props: {
        url: {
            type: String,
            default: '' // pdfUrl
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentVisible: this.visible,
            pdfUrl: this.url
        };
    },
    waatch: {
        visible(val, old) {
            if (val === old) return;
            this.currentVisible = val;
            // if (val) {
                
            // }
        }
    },
    created() {
        console.log(this.visible, ';;;,;', this.currentVisible);
    },
    methods: {
        handleVisible(val) {
            if (val) {
                console.log('ooooo');
                this.currentVisible = true;
                this.pdfUrl = val;
                // this.currentVisible = true;
                // const { fileUrl, fileName } = this.props;
                // if (typeof val !== 'object') {
                //     // 补全
                //     this.pdfInfo = {
                //         [fileUrl]: val,
                //         [fileName]: val
                //     };
                // } else if (val[fileUrl]) {
                //     this.pdfInfo = val;
                // } else {
                //     this.currentVisible = false;
                //     console.error('缺少有效地址');
                // }
            }
        },
        close() {
            this.currentVisible = false;
            this.$emit('update:visible', false);
        }
    }
};
</script>

<style lang="less">
.PDF_dialog_box{
    width:100%;
    height: 100%;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#333;
    z-index: 9999;
}
.PDF_dialog_close{
    font-size: 14px;
    text-align: right;
    padding:0 30px;
    color:#ccc;
    cursor: pointer;
}
</style>>