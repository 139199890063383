import {
    SET_USERINFO,
    CLEAR_USERINFO,
    SET_MENUID,
    GET_APPLY_MENU,
    SET_PAGE_TAGS,
    DELETE_PAGE_TAGS,
    CLEAR_PAGE_TAGS,
    SET_MESSAGE_COUNT,
    SET_MESSAGE_COUNT_DOWN,
    SET_TASK_TYPE,
    SAVE_HOSP_DATA,
    SAVE_USER_MODULE_NUM,
    SET_WEBNAME,
    SET_PROJECT
} from './mutation-type';

export default {
    // 网站名称
    [SET_WEBNAME](state, data) {
        state.webName = data;
    },
    // 项目模块
    [SET_PROJECT](state, data) {
        state.projectName = data;
    },
    // 用户消息数量
    [SET_MESSAGE_COUNT](state, data) {
        state.message = data;
    },
    // 读取消息每次减1；
    [SET_MESSAGE_COUNT_DOWN](state) {
        state.message -= 1;
    },
    [SET_USERINFO](state, data) {
        state.userInfo = data;
    },
    [CLEAR_USERINFO](state) {
        state.userInfo = null;
    },
    [SET_MENUID](state, data) {
        state.menuID = data.menuid;
    },
    // 获取应用菜单
    [GET_APPLY_MENU](state, data) {
        // portal页面保存的userType;
        const userTp = localStorage.getItem('userType');
        const result = [];
        data.forEach(item => {
            if (item.path === userTp) {
                result.push(item);
            }
        });
        state.applyMeunList = result;
    },
    // 设置页签数据
    [SET_PAGE_TAGS](state, data) {
        const { pageTagsData } = state;
        if (pageTagsData.length === 5) {
            state.pageTagsData.shift();
        }
        const index = pageTagsData.findIndex(tag => JSON.stringify(tag) === JSON.stringify(data));
        if (index === -1) {
            state.pageTagsData.push(data);
        }
    },
    // 删除页签数据
    [DELETE_PAGE_TAGS](state, data) {
        state.pageTagsData.splice(data, 1);
    },
    // 清除空页签数据
    [CLEAR_PAGE_TAGS](state) {
        state.pageTagsData = [];
    },
    [SET_TASK_TYPE](state, data) {
        state.taskType = data;
    },
    // 保存医院数据
    [SAVE_HOSP_DATA](state, data) {
        // console.log(data);
        state.hospData[data.key] = data.data;
    },
    // 保存用户模块数量
    [SAVE_USER_MODULE_NUM](state, data) {
        state.userModuleNum = data;
    }
};
