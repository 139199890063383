/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-14 15:39:35
 * @Last Modified by: hejk
 * @Last Modified time: 2020-09-08 16:44:59
 * @Description: 表单配置
 */
import productCate from '@/utils/options/product-cate';
import { baseAreaList } from '@/api/audit';

export default {
    forms: {
        企业名称: {
            name: 'orgInfoName',
            label: '企业名称',
            type: 'input',
            disabled: true,
            rules: [
                {
                    required: true,
                    message: '请输入企业名称',
                    trigger: 'blur'
                }
            ]
        },
        企业简称: {
            name: 'orgShortName',
            label: '企业简称',
            type: 'input',
            extend: {
                maxlength: 50
            }
        },
        注册资金: {
            name: 'registerCapitalAmt',
            label: '注册资金（万元）',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入注册资金',
                    trigger: 'blur'
                },
                { 
                    validator: (rule, value, callback) => {
                        const reg = /^([1-9]\d{0,9}|0)(\.\d{1,4})?$/;
                        if (value.match(reg) === null) {
                            callback(new Error('注册资金格式不正确'));
                        } else {
                            callback();
                        }
                    },
                    trigger: ['blur'] 
                }
            ]
        },
        成立日期: {
            name: 'orgSetupDate',
            label: '成立日期',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: '请选择成立日期',
                    trigger: 'blur'
                }
            ]
        },
        企业类型: {
            name: 'orgType',
            label: '企业类型',
            type: 'radio',
            disabled: true,
            options: [
                {
                    label: '医疗器械（医用耗材）生产企业',
                    value: 1
                },
                {
                    label: '医疗器械（医用耗材）代理企业',
                    value: 2
                },
                {
                    label: '医疗器械（医用耗材）配送企业',
                    value: 3
                },
                {
                    label: '医疗器械（医用耗材）生产及代理企业',
                    value: 4
                }

            ],
            rules: [
                {
                    required: true,
                    message: '请选择企业类型',
                    trigger: 'blur'
                }
            ]
        },
        国家: {
            name: 'countryId',
            label: '所在地区',
            type: 'select',
            options: [
                { label: '中国', value: '0' }
            ],
            placeholder: '请选择国家',
            rules: [
                {
                    required: true,
                    message: '请选择国家',
                    trigger: 'change'
                }
            ],
            grid: 4
        },
        省份: {
            name: 'provinceId',
            label: '',
            type: 'select',
            http: {
                url: baseAreaList,
                method: 'get',
                params: {
                    parentId: 0
                }
            },
            props: {
                label: 'name',
                value: 'areaId'
            },
            placeholder: '请选择省份',
            rules: [
                {
                    required: true,
                    message: '请选择省份',
                    trigger: 'change'
                }
            ],
            grid: 4
        },
        城市: {
            name: 'cityId',
            label: '',
            type: 'select',
            http: {
                url: baseAreaList,
                method: 'get',
                params: {
                    parentId: -1
                },
                initSwitch: false
            },
            props: {
                label: 'name',
                value: 'areaId'
            },
            depend: {
                name: 'provinceId',
                clearSwitch: false,
                onChange: (val, that) => {
                    if (val !== null && val !== '' && val !== undefined) {
                        that.materialConfig.http.params.parentId = val;
                    } else {
                        that.materialConfig.http.params.parentId = -1;
                    }
                }
            },
            placeholder: '请选择城市',
            rules: [
                {
                    required: true,
                    message: '请选择城市',
                    trigger: 'change'
                }
            ],
            grid: 4
        },
        区县: {
            name: 'districtId',
            label: '',
            type: 'select',
            http: {
                url: baseAreaList,
                method: 'get',
                params: {
                    parentId: -1
                },
                initSwitch: false
            },
            props: {
                label: 'name',
                value: 'areaId'
            },
            depend: {
                name: 'cityId',
                onChange: (val, that) => {
                    if (val !== null && val !== '' && val !== undefined) {
                        that.materialConfig.http.params.parentId = val;
                    } else {
                        that.materialConfig.http.params.parentId = -1;
                    }
                },
                clearSwitch: false
            },
            placeholder: '请选择区县',
            rules: [
                {
                    required: true,
                    message: '请选择城区县',
                    trigger: 'change'
                }
            ],
            grid: 4
        },
        联系地址: {
            name: 'contactAddress',
            label: '联系地址',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入联系地址',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 100
            }
        },
        备注: {
            name: 'remark',
            label: '备注',
            type: 'input',
            extend: {
                maxlength: 2000
            }
        },

        产品类别: {
            name: 'productType',
            label: '产品类别',
            type: 'checkbox',
            options: productCate,
            visible: true,
            rules: [
                {
                    required: true,
                    message: '请选择产品类别',
                    trigger: 'blur'
                }
            ]
        },
        企业法人姓名: {
            name: 'orgLegalName',
            label: '企业法人姓名',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入企业法人姓名',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        所在地区: {
            name: 'address',
            label: '所在地区'
        },
        初审状态: {
            name: 'auditStatusDesc',
            label: '初审状态',
            grid: 2
        },
        审核时间: {
            name: 'auditTime',
            label: '审核时间',
            grid: 2
        },
        初审意见: {
            name: 'auditOpinion',
            label: '初审意见',
            type: 'textarea',
            grid: 2
        },
        注册证编号: {
            label: '注册证编号',
            name: 'cardNo',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入注册证编号',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        注册证产品名称: {
            label: '注册证产品名称',
            name: 'cardName',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入注册证产品名称',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        注册证有效期起: {
            label: '注册证有效期起',
            name: 'validityStart',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: '请选择注册证有效期起',
                    trigger: 'blur'
                }
            ]
        },
        注册证有效期止: {
            label: '注册证有效期止',
            name: 'validityEnd',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: '请选择注册证有效期止',
                    trigger: 'blur'
                }
            ]
        },
        生产企业: {
            label: '生产企业',
            name: 'produceOrg',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入生产企业',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        申报企业: {
            type: 'input',
            label: '申报企业',
            name: 'orgName',
            disabled: true
        },
        管理类别: {
            label: '管理类别',
            name: 'managementType',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: '请选择管理类别',
                    trigger: 'change'
                }
            ],
            options: [
                {
                    label: '一类',
                    value: 1
                },
                {
                    label: '二类',
                    value: 2
                },
                {
                    label: '三类',
                    value: 3
                }
            ]
        },
        备注1: {
            label: '备注',
            name: 'registerRemark',
            type: 'textarea',
            extend: {
                maxlength: 2000
            }
        },
        产品名称: {
            label: '产品名称',
            name: 'productName',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入产品名称',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        产品材质: {
            label: '产品材质',
            name: 'productMaterial',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入产品材质',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 32
            }
        },
        最小包装单位: {
            label: '最小包装单位',
            name: 'packUnit',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入最小包装单位',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 32
            }
        },
        分类名称: {
            label: '分类名称',
            name: 'catalogParentName',
            type: 'input',
            readonly: true,
            rules: [
                {
                    required: true,
                    message: '请选择分类名称',
                    trigger: 'blur'
                }
            ]
        },
        产地: {
            label: '产地',
            name: 'productAreaType',
            type: 'select',
            rules: [
                {
                    required: true,
                    message: '请选择产地',
                    trigger: 'blur'
                }
            ],
            options: [
                {
                    label: '国产',
                    value: 1
                },
                {
                    label: '进口',
                    value: 2
                },
                {
                    label: '港澳台',
                    value: 3
                }
            ]
        },
        产品标准: {
            label: '产品标准',
            name: 'productStandard',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入产品标准',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 32
            }
        },
        产品有效期: {
            label: '产品有效期',
            name: 'expiration',
            type: 'input'
        },
        性能结构与组成: {
            label: '性能结构与组成',
            name: 'makeUp',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: '请输入性能结构与组成',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 2000
            }
        },
        适用范围: {
            label: '适用范围',
            name: 'scope',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: '请输入适用范围',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 2000
            }
        },
        产品使用方法: {
            label: '产品使用方法',
            name: 'productUsage',
            type: 'textarea',
            extend: {
                maxlength: 2000
            }
        },
        规格: {
            label: '规格',
            name: 'standard',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: '请输入规格',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        型号: {
            label: '型号',
            name: 'model',
            type: 'textarea',
            rules: [
                {
                    required: true,
                    message: '请输入型号',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 50
            }
        },
        编号: {
            label: '编号',
            name: 'singleNo'
        },
        发布日期: {
            label: '发布日期',
            name: 'releaseDate',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: '请选择发布日期',
                    trigger: 'blur'
                }
            ]
        },
        目录名称: {
            label: '目录名称',
            name: 'catalogName',
            type: 'input',
            readonly: true,
            rules: [
                {
                    required: true,
                    message: '请输入目录名称',
                    trigger: 'blur'
                }
            ]
        },
        最小计量单位: {
            label: '最小计量单位',
            name: 'minUnit',
            type: 'input',
            readonly: true,
            rules: [
                {
                    required: true,
                    message: '请输入最小计量单位',
                    trigger: 'blur'
                }
            ]
        },
        // 国家统一编码: {
        //     label: '国家统一编码',
        //     name: 'nationalCode',
        //     type: 'input',
        //     readonly: true
        // },
        换算比: {
            label: '换算比',
            name: 'conversionRatio',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入换算比',
                    trigger: 'blur'
                },
                { 
                    validator: (rule, value, callback) => {
                        const reg = /^([1-9]\d{0,9}|0)(\.\d{1,4})?$/;
                        if (value.match(reg) === null) {
                            callback(new Error('换算比格式不正确'));
                        } else {
                            callback();
                        }
                    },
                    trigger: ['blur'] 
                }
            ]
        },
        产品最小包装: {
            label: '产品最小包装',
            name: 'minPackStandard',
            type: 'input',
            readonly: true,
            rules: [
                {
                    required: true,
                    message: '请输入产品最小包装',
                    trigger: 'blur'
                }
            ]
        },
        包装材质: {
            label: '包装材质',
            name: 'productPackMaterial',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入包装材质',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 32
            }
        },
        '品牌/商标': {
            label: '品牌/商标',
            name: 'brandMark',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入品牌/商标',
                    trigger: 'blur'
                }
            ],
            extend: {
                maxlength: 32
            }
        },
        类型: {
            label: '类型',
            name: 'regType',
            type: 'select',
            options: [
                {
                    label: '第一类医疗器械产品',
                    value: 1
                },
                {
                    label: '二类医疗器械产品',
                    value: 2
                },
                {
                    label: '三类医疗器械产品',
                    value: 5
                },
                {
                    label: '消毒类产品',
                    value: 3
                },
                {
                    label: '分类界定产品',
                    value: 4
                }
            ]
        },
        复审状态: {
            name: 'reAuditStatusDesc',
            label: '复审状态',
            grid: 2
        },
        复审意见: {
            name: 'reAuditOpinion',
            label: '复审意见',
            grid: 2
        },
        是否撤销申报: {
            name: 'revokeApplyFlag',
            label: '是否撤销申报',
            type: 'radio',
            grid: 2,
            visible: false,
            options: [
                {
                    label: '是',
                    value: true
                },
                {
                    label: '否',
                    value: false
                }
            ]
        },
        医疗器械唯一标识信息编码: {
            label: '医疗器械唯一标识信息编码',
            name: 'singleCode',
            type: 'textarea'
        },
        通用名: {
            label: '通用名',
            name: 'commonName',
            type: 'input',
            grid: 2
        },
        医保医用耗材分类代码: {
            label: '医保医用耗材分类代码',
            name: 'nationalCode',
            type: 'input',
            readonly: true
        },
        产品编号: {
            name: 'productCode',
            label: '产品编号',
            type: 'input',
            grid: 2
        },
        资质: {
            label: '资质',
            name: 'fieldValue',
            grid: 1,
            type: 'upload',
            readonly: true,
            rules: [
                {
                    required: true,
                    message: '请选中关联资质',
                    trigger: 'change'
                }
            ]
        },
        药监分类原分类目录: {
            name: 'oldMedicineCatalog',
            label: '药监分类原分类目录',
            type: 'input',
            grid: 1,
            readonly: true
        },
        药监分类新分类目录: {
            name: 'newMedicineCatalog',
            label: '药监分类新分类目录',
            type: 'input',
            grid: 1,
            readonly: true
        },
        审核结果: {
            label: '审核结果',
            name: 'secondAuditStatusDesc'
        },
        审核意见: {
            label: '审核意见',
            name: 'secondAuditOpinion'
        }
    }
};
