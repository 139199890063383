/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:05:28
 * @Last Modified by: qiuwt
 * @Last Modified time: 2020-02-29 16:24:45
 * @Description: 空路由容器
 * @Route: Route
 */
<template>
    <div class="main-container">
        <keep-alive ref="keep">
            <router-view v-if="$route.meta.display !== 'none'"
                         ref="page" />
        </keep-alive>
        <router-view v-if="$route.meta.display==='none'" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            /**
             * 缓存加载id
             *  */

            cacheId: {},
            cacheObj: {},
            /** *
             * 记录tag变化
             */
            oldTags: '[]'
        };
    },
    computed: {
        ...mapGetters(['pageTagsData'])
    },
    // watch: {
    //     pageTagsData(val) {
    //         const str = JSON.stringify(val);
    //         if (str === this.oldTags) return;

    //         let oldArr = JSON.parse(this.oldTags);
    //         if (oldArr.length) {
    //             oldArr = oldArr.map(item => item.path);
    //             const nowArr = val.map(item => item.path);
    //             const allArr = [...new Set([...oldArr], [...nowArr])];
    //             // 新增的id
    //             const addIds = allArr.filter(item => oldArr.indexOf(item) === -1);
    //             // 被删除的id
    //             const removeIds = allArr.filter(item => nowArr.indexOf(item) === -1);
    //             removeIds.forEach(r => {
    //                 this.clearCache(r);
    //             });
    //         }
    //         this.addCacheId();

    //         this.oldTags = str;
    //     }
    // },
    mounted() {
    },
    methods: {
        /**
         * 清除缓存
         *  */
        // clearCache(path) {
        //     const vm = this;
        //     if (vm._isBeingDestroyed) {
        //         return;
        //     }
        //     callHook(vm, 'beforeDestroy');
        //     vm._isBeingDestroyed = true;
        //     // remove self from parent
        //     const parent = vm.$parent;
        //     if (parent && !parent._isBeingDestroyed && !vm.$options.abstract) {
        //         remove(parent.$children, vm);
        //     }
        //     // teardown watchers
        //     if (vm._watcher) {
        //         vm._watcher.teardown();
        //     }
        //     let i = vm._watchers.length;
        //     while (i--) {
        //         vm._watchers[i].teardown();
        //     }
        //     // remove reference from data ob
        //     // frozen object may not have observer.
        //     if (vm._data.__ob__) {
        //         vm._data.__ob__.vmCount--;
        //     }
        //     // call the last hook...
        //     vm._isDestroyed = true;
        //     // invoke destroy hooks on current rendered tree
        //     vm.__patch__(vm._vnode, null);
        //     // fire destroyed hook
        //     callHook(vm, 'destroyed');
        //     // turn off all instance listeners.
        //     vm.$off();
        //     // remove __vue__ reference
        //     if (vm.$el) {
        //         vm.$el.__vue__ = null;
        //     }
        //     // release circular reference (#6759)
        //     if (vm.$vnode) {
        //         vm.$vnode.parent = null;
        //     }
        // },
        /** *
         * 保存当前页面到缓存项中
         *  */
        addCacheId() {
            const { fullPath, meta } = this.$route;
            if (meta.display !== 'none') {
                let name = this.$refs.page.$vnode.tag;
                name = name.replace(/^vue-component-/, '');
                this.cacheId[fullPath] = name;
                // this.cacheObj[fullPath] = this;
            }
        }
    }
};
</script>
