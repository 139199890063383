/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-07-07 10:22:12
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-13 18:04:17
 * @Description: 管理端 项目外配送关系调整路由
 * @Route: distribution-relationship
 */


import View from '@/components/view';

// 配送关系调整
const DistributionRelationship = () => import(/* webpackChunkName: "DistributionRelationship" */ '@/pages/aup/manage-org/proj-out/distribution-relationship');
const RelationshipDetail = () => import(/* webpackChunkName: "RelationshipDetail" */ '@/pages/aup/manage-org/proj-out/distribution-relationship/detail');
const DistributionAdjust = () => import(/* webpackChunkName: "DistributionAdjust" */ '@/pages/aup/manage-org/proj-out/distribution-relationship/adjust');
const DistributionAdjustLog = () => import(/* webpackChunkName: "DistributionAdjustLog" */ '@/pages/aup/manage-org/proj-out/distribution-relationship/adjust-log');
export default [
    {
        path: 'distribution-relationship',
        component: View,
        meta: {
            title: '配送关系调整'
        },
        children: [
            {
                path: 'list',
                component: DistributionRelationship,
                meta: {
                    title: '配送关系调整'
                }
            },
            {
                path: 'adjust-log/detail',
                component: RelationshipDetail,
                meta: {
                    title: '调整详情',
                    display: 'none'
                }
            },
            {
                path: 'adjust',
                component: DistributionAdjust,
                meta: {
                    title: '直接调整',
                    display: 'none'
                }
            },
            {
                path: 'adjust-log',
                component: DistributionAdjustLog,
                meta: {
                    title: '调整日志',
                    display: 'none'
                }
            }
        ]
    }
];
