/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-09 15:09:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-06-18 16:28:59
 * @Description: 报量管理
 */
import View from '@/components/view';
import fetch from '@/utils/fetch';

const PurchaseQuantityStatistic = () => import(/* webpackChunkName: "gl-purchase-quantity-statistic" */ '@/pages/aup/manage-org/proj-inner/volume-manage/purchase-quantity-statistic');
const PurchaseQuantityFill = () => import(/* webpackChunkName: "gl-purchase-quantity-fill" */ '@/pages/aup/manage-org/proj-inner/volume-manage/purchase-quantity-fill');
const PurchaseQuantityAudit = () => import(/* webpackChunkName: "gl-purchase-quantity-audit" */ '@/pages/aup/manage-org/proj-inner/volume-manage/purchase-quantity-audit');
const FillPage = () => import(/* webpackChunkName: "gl-fill-page" */ '@/pages/aup/manage-org/proj-inner/volume-manage/fill-page');
const routes = [
    {
        path: 'volume-manage',
        component: View,
        meta: {
            title: '报量管理'
        },
        children: [
            {
                path: 'purchase-quantity-fill',
                component: PurchaseQuantityFill,
                meta: {
                    title: '采购量填报',
                    display: 'none'
                }
            },
            {
                path: 'purchase-quantity-fill-before',
                component: () => import('@/pages/common/notice'),
                meta: {
                    title: '采购量填报'
                },
                beforeEnter: (to, from, next) => {
                    fetch({
                        url: '/hxupsc/api/quantity/filling/status',
                        method: 'get',
                        params: {
                            projectId: to.query.itemId || 1
                        }
                    }).then(res => {
                        const { status } = res.data;
                        to.query.startTime = res.data.startTime;
                        to.query.endTime = res.data.endTime;
                        if (status === 0) {
                            to.query.title = '采购量填报未开始！';
                            next();
                        } else if (status === 3) {
                            to.query.title = '采购量填报已结束！';
                            next();
                        } else if (status === 2) {
                            to.query.title = '采购量填报已暂停！';
                            next();
                        } else {
                            next({
                                path: '/hxupsc/manage-org/proj-inner/volume-manage/purchase-quantity-fill',
                                query: from.query
                            });
                        }
                    });
                }
            },
            {
                path: 'purchase-quantity-audit',
                component: PurchaseQuantityAudit,
                meta: {
                    title: '采购报量审核'
                }
            },
            {
                path: 'purchase-quantity-statistic',
                component: PurchaseQuantityStatistic,
                meta: {
                    title: '采购报量统计'
                }
            },
            {
                path: 'purchase-quantity-fill/FillPage',
                component: FillPage,
                meta: {
                    title: '报量页面',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
