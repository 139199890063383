/*
 * @Author: qiuwt
 * @Email: qiuwt@guahao.com
 * @Date: 2019-08-29 23:01:46
 * @Last Modified by: tangliang
 * @Last Modified time: 2019-11-26 11:24:29
 * @Description: api接口
 */
// 登录
export const apiLogin = '/auth/oauth/token';
// 更新token
export const apiQueryToken = '/auth/oauth/token';
// 登出
export const removeToken = '/auth/token/removeToken';
