/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-25 14:45:51
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-11-20 19:05:45
 * @Description: 项目配置
 */

import View from '@/components/view';

const QuotationConsole = () => import(/* webpackChunkName: "gl-quotation-console" */ '@/pages/aup/manage-org/proj-inner/project-config/index');
const ProjectParamConfig = () => import(/* webpackChunkName: "gl-project-param-config" */ '@/pages/aup/manage-org/proj-inner/project-config/project-param-config');
const ProjectSwitchConfig = () => import(/* webpackChunkName: "gl-project-switch-config" */ '@/pages/aup/manage-org/proj-inner/project-config/project-switch-config');
const DecryptPubConfig = () => import(/* webpackChunkName: "gl-decrypt-pub-config" */ '@/pages/aup/manage-org/proj-inner/project-config/decrypt-pub-config');
const ProjectExpertConfig = () => import(/* webpackChunkName: "gl-project-expert-config" */ '@/pages/aup/manage-org/proj-inner/project-config/project-expert-config');
const expertGroupConfig = () => import(/* webpackChunkName: "gl-expert-group-config" */ '@/pages/aup/manage-org/proj-inner/project-config/expert-group-config');
const supplementCorporateInformation = () => import(/* webpackChunkName: "gl-supplement-corporate-information" */ '@/pages/aup/manage-org/proj-inner/project-config/supplement-corporate-information');
const list = () => import(/* webpackChunkName: "gl-supplement-corporate-information-list" */ '@/pages/aup/manage-org/proj-inner/project-config/supplement-corporate-information/list');
const field = () => import(/* webpackChunkName: "gl-supplement-corporate-information-field" */ '@/pages/aup/manage-org/proj-inner/project-config/supplement-corporate-information/field');
const historyFillConfig = () => import(/* webpackChunkName: "gl-history-fill-config" */ '@/pages/aup/manage-org/proj-inner/project-config/history-fill-config');
const routes = [
    {
        path: 'project-config',
        component: View,
        meta: {
            title: '项目配置'
        },
        children: [
            {
                path: 'quotation-console',
                component: QuotationConsole,
                meta: {
                    title: '报价控制台'
                }
            },
            {
                path: 'project-param-config',
                component: ProjectParamConfig,
                meta: {
                    title: '项目参数配置'
                }
            },
            {
                path: 'project-switch-config',
                component: ProjectSwitchConfig,
                meta: {
                    title: '项目开关配置'
                }
            },
            {
                path: 'decrypt-pub-config',
                component: DecryptPubConfig,
                meta: {
                    title: '解密公钥配置'
                }
            },
            {
                path: 'project-expert-config',
                component: ProjectExpertConfig,
                meta: {
                    title: '项目专家管理'
                }
            },
            {
                path: 'expert-group-config',
                component: expertGroupConfig,
                meta: {
                    title: '专家组配置'
                }
            },
            {
                path: 'supplement-corporate-information',
                component: supplementCorporateInformation,
                meta: {
                    title: '企业增补资料配置'
                }
            },
            {
                path: 'supplement-corporate-information/list',
                component: list,
                meta: {
                    title: '企业增补资料字段',
                    display: 'none',
                    noTag: true
                }
            },
            {
                path: 'supplement-corporate-information/list/field',
                component: field,
                meta: {
                    title: '企业增补资料字段信息',
                    display: 'none',
                    noTag: true
                }
            },
            {
                path: 'history-fill-config',
                component: historyFillConfig,
                meta: {
                    title: '历史价格填报配置'
                }
            }
        ]
    }
];

export default routes;
