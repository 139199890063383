/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-14 15:39:35
 * @Last Modified by: hejk
 * @Last Modified time: 2020-09-01 14:41:18
 * @Description: 列表项配置
 */

const minWid = {
    extend: {
        minWidth: 120
    }
};

export default {
    cols: {
        初审状态: {
            name: 'auditStatusDesc',
            label: '初审状态',
            ...minWid
        },
        注册证状态: {
            name: 'regCardStatusDesc',
            label: '注册证状态',
            ...minWid
        },
        产品名称: {
            name: 'productName',
            label: '产品名称',
            extend: {
                minWidth: 250
            }
        },
        注册证编号: {
            name: 'cardNo',
            label: '注册证编号',
            extend: {
                minWidth: 250
            }
        },
        注册证名称: {
            name: 'cardName',
            label: '注册证名称',
            extend: {
                minWidth: 250
            }
        },
        规格: {
            name: 'standard',
            label: '规格',
            ...minWid
        },
        型号: {
            name: 'model',
            label: '型号',
            ...minWid
        },
        医疗器械: {
            name: '6',
            label: '医疗器械',
            ...minWid
        },
        生产企业: {
            name: 'produceOrg',
            label: '生产企业',
            ...minWid
        },
        申报企业: {
            name: 'orgName',
            label: '申报企业',
            ...minWid
        },
        最后修改时间: {
            name: 'gmtModified',
            label: '最后修改时间',
            ...minWid
        },
        材质: {
            name: 'productMaterial',
            label: '材质',
            ...minWid
        },
        规格数量: {
            name: 'standardQty',
            label: '规格数量'
        },
        编号: {
            name: 'singleNo',
            label: '编号',
            ...minWid
        },
        备注: {
            name: 'remark',
            label: '备注',
            ...minWid
        },
        提交人: {
            name: 'submitPerson',
            label: '提交人',
            ...minWid
        },
        提交时间: {
            name: 'submitTime',
            label: '提交时间',
            ...minWid
        },
        申请操作: {
            name: 'optTypeDesc',
            label: '申请操作'
        },
        初审审核人: {
            name: 'auditPerson',
            label: '初审审核人',
            ...minWid
        },
        初审时间: {
            name: 'auditTime',
            label: '初审时间',
            ...minWid
        },
        初审意见: {
            name: 'auditOpinion',
            label: '初审意见',
            ...minWid
        },
        企业名称: {
            name: 'orgInfoName',
            label: '企业名称',
            ...minWid
        },
        生产经营许可证号: {
            name: 'productionLicenseNo',
            label: '生产(经营)许可证号',
            extend: {
                minWidth: 180,
                formatter: row => (row.orgType === 1 ? row.productionLicenseNo : row.operateLicenseNo)
            }
        },
        营业期限至: {
            name: 'businessLicenseDateEnd',
            label: '营业期限至',
            ...minWid
        },
        被授权人姓名: {
            name: 'authorizedPersonName',
            label: '被授权人姓名',
            ...minWid
        },
        被授权人手机: {
            name: 'authorizedPersonPhone',
            label: '被授权人手机',
            ...minWid
        },
        联系地址: {
            name: 'contactAddress',
            label: '联系地址',
            ...minWid
        },
        企业类型: {
            name: 'orgTypeDesc',
            label: '企业类型',
            ...minWid
        },
        状态: {
            name: 'optTypeDesc',
            label: '状态'
        },
        公示时间: {
            name: 'publicityTime',
            label: '公示时间',
            ...minWid
        },
        管理类别: {
            name: 'managementTypeDesc',
            label: '管理类别'
        },
        产品编号: {
            name: 'productCode',
            label: '产品编号',
            ...minWid
        },
        类型: {
            name: 'regTypeDesc',
            label: '类型',
            ...minWid
        },
        分类名称: {
            name: 'catalogParentName',
            label: '分类名称',
            ...minWid
        },
        目录名称: {
            name: 'catalogName',
            label: '目录名称',
            ...minWid
        },
        分类编号: {
            name: 'pcatalogNum',
            label: '分类编号',
            ...minWid
        },
        目录编号: {
            name: 'catalogNum',
            label: '目录编号',
            ...minWid
        },
        最小计量单位: {
            name: 'unit',
            label: '最小计量单位',
            ...minWid
        },
        注册证有效期至: {
            name: 'validityEnd',
            label: '注册证有效期至',
            ...minWid
        },
        复审状态: {
            name: 'reAuditStatusDesc',
            label: '复审状态',
            ...minWid
        },
        复审审核人: {
            name: 'reAuditPerson',
            label: '复审审核人',
            ...minWid
        },
        复审时间: {
            name: 'reAuditTime',
            label: '复审时间',
            ...minWid
        },
        复审意见: {
            name: 'reAuditOpinion',
            label: '复审意见',
            ...minWid
        },
        所在地区: {
            name: 'area',
            label: '所在地区',
            ...minWid
        },
        撤销申报: {
            name: 'revokeApplyFlagDesc',
            label: '撤销申报'
        },
        医疗器械唯一标识信息编码: {
            label: '医疗器械唯一标识信息编码',
            name: 'singleCode',
            extend: {
                minWidth: 200
            }
        },
        通用名: {
            label: '通用名',
            name: 'commonName'
        },
        单一规格产品编码: {
            label: '单一规格产品编码',
            name: 'singleProductCode'
        },
        医用耗材代码: {
            label: '医用耗材代码',
            name: 'nationalCode'
        },
        '一级（学科、品类）': {
            label: '一级（学科、品类）',
            name: 'levelOne'
        },
        '二级（用途、品目）': {
            label: '二级（用途、品目）',
            name: 'levelTwo'
        },
        '三级（部位、功能、品种）': {
            label: '三级（部位、功能、品种）',
            name: 'levelThree',
            extend: {
                minWidth: 200
            }
        },
        医保通用名: {
            label: '医保通用名',
            name: 'medicareName'
        },
        '规格（特征、参数）': {
            label: '规格（特征、参数）',
            name: 'spec'
        },
        单件产品名称: {
            label: '单件产品名称',
            name: 'itemName'
        },
        规格型号数: {
            label: '规格型号数',
            name: 'specificNo'
        },
        医保医用耗材分类代码: {
            label: '医保医用耗材分类代码',
            name: 'nationalCode'
        },
        prod复审状态: {
            name: 'secondAuditStatusDesc',
            label: '复审状态',
            ...minWid
        },
        prod复审审核人: {
            name: 'secondAuditPerson',
            label: '复审审核人',
            ...minWid
        },
        prod复审时间: {
            name: 'secondAuditTime',
            label: '复审时间',
            ...minWid
        },
        prod复审意见: {
            name: 'secondAuditOpinion',
            label: '复审意见',
            ...minWid
        }

    }
};
