/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-02-18 10:35:13
 * @Description: 竞价管理
 */

import View from '@/components/view';

const biddingManage = () => import(/* webpackChunkName: "gl-bidding-manage-index" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/index');
const biddingResult = () => import(/* webpackChunkName: "gl-bidding-manage-result" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/result');

const routes = [
    {
        path: 'bidding',
        component: View,
        meta: {
            title: '竞价管理'
        },
        children: [
            {
                path: 'manage',
                component: biddingManage,
                meta: {
                    title: '竞价解密-已开始',
                    display: 'none'
                }
            },
            {
                path: 'decrypt-before',
                component: () => import('@/pages/common/notice'),
                meta: {
                    title: '竞价解密'
                }
                // beforeEnter(to, from, next) {
                //     fetch({
                //         url: '/hxupsc/api/project/param/round/config/get_round_status',
                //         method: 'post',
                //         params: {
                //             projectId: to.query.itemId || 1,
                //             configType: 1
                //         }
                //     }).then(res => {
                //         const { startTime, endTime, status, roundNum } = res.data;
                //         to.query.startTime = startTime;
                //         to.query.endTime = endTime;
                //         if (status === 0) {
                //             to.query.title = '竞价解密环节未开始，请耐心等待！';
                //             next();
                //         } else if (status === 3) {
                //             to.query.title = '竞价解密环节已结束！';
                //             next();
                //         } else if (status === 2) {
                //             to.query.title = '竞价解密环节已暂停，请耐心等待！';
                //         } else {
                //             next({
                //                 path: '/hxupsc/manage-org/proj-inner/bidding/manage',
                //                 query: Object.assign({ roundNum }, from.query)
                //             });
                //         }
                //     });
                // }
            },
            {
                path: 'result',
                component: biddingResult,
                meta: {
                    title: '竞价结果管理'
                }
            }
        ]
    }
];

export default routes;
