import Fetch from '@/utils/fetch';
import { removeToken } from '@/api/api';

// CA证书登录
export const CALogin = params => (
    Fetch({
        url: '/api/portal/user/calogin',
        method: 'post',
        params
    })
);


// 校验手机号是否存在
export const checkMobile = () => (
    Fetch({
        url: '/api/checkMobile'
    })
);

// 验证验证码 new
export const queryCheckCode = params => (
    Fetch({
        url: '/api/comp/vcode/image/verify',
        params
    })
);

// // 忘记密码修改
// export const changePassword = params => (
//     Fetch({
//         url: '/api/portal/user/resetpassword',
//         params,
//         method: 'post'
//     })
// );

// 获取随机数
export const getRandom = () => (
    Fetch({
        url: '/api/portal/user/readyca'
    })
);
// 获取CA相关信息
export const getCaInfo = params => (
    Fetch({
        url: '/api/portal/user/getcainfo',
        method: 'post',
        params
    })
);
// 绑定用户CA证书
export const bindUserCA = params => (
    Fetch({
        url: '/api/portal/user/bindca',
        method: 'post',
        params
    })
);
// 解绑用户CA证书
export const unBindUserCA = params => (
    Fetch({
        url: '/api/portal/user/unbindca',
        method: 'post',
        params
    })
);

// 获取部门列表
export const getDepartmentList = params => (
    Fetch({
        url: '/api/portal/department/pagelist',
        method: 'post',
        params
    })
);

// 删除部门
export const deleteDepartment = params => (
    Fetch({
        url: '/api/portal/department/deletedbydeptid',
        params
    })
);


// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 获得对应的字段
// 菜单 new
export const queryMenu = params => (
    Fetch({
        url: localStorage.getItem('userType') === 'hxupsc' ? '/hxupsc/api/project/menu/outer' : '/upms/menu',
        params
    })
);

// 获取项目菜单 new
export const getProjectMenu = params => (
    Fetch({
        url: localStorage.getItem('userType') === 'hxupsc' ? '/hxupsc/api/project/menu/inner' : '/upms/menu',
        params
    })
);

// 获取用户信息 new
export const getUserInfo = () => (
    Fetch({
        url: '/upms/user/getInfo',
        params: {
            id: localStorage.getItem('userKey')
        },
        method: 'get'
    })
);
// 登出 new
export const userLoginout = params => (
    Fetch({
        url: removeToken,
        params
    })
);
// 获得字典
export const getLableValueByKey = params => (
    Fetch({
        url: '/upms/sysDictIndex/getLableValueByKey',
        params
    })
);
// 获得对应权限列表
export const getMenuListByRoleId = params => (
    Fetch({
        url: '/upms/sysRole/getOrgMenuByRoleId',
        params
    })
);
// 获得对应权限列表
export const getMenuListPlat = params => (
    Fetch({
        url: '/upms/sysRole/getUserMenu',
        params
    })
);
// 获得对应权限列表
export const saveRoleMenuFetch = params => (
    Fetch({
        url: '/upms/sysRole/saveMenu',
        method: 'post',
        params
    })
);
// portal
export const getPortalList = params => (
    Fetch({
        url: '/upms/sysSystem/getList',
        method: 'get',
        params
    })
);

// 获得机构信息
export const getOrgInfo = params => (
    Fetch({
        url: '/trade/api/base/org_info/detail',
        method: 'get',
        params
    })
);

// 查看消息信息
export const readMessageApi = params => (
    Fetch({
        url: '/upms/sysMessage/getInfo',
        method: 'get',
        params
    })
);

// getMessaeCount
export const getMessaeCount = params => (
    Fetch({
        url: '/upms/sysMessage/queryMsgNum',
        method: 'get',
        params
    })
);
// 获取任务类型
/**
 *  @author zhuangmw
 */

export const getTaskType = params => (
    Fetch({
        url: '/trade/api/base/enums/query_enums',
        method: 'get',
        params
    })
);

// drugHospDrugUpdateHisCode
export const drugHospDrugUpdateHisCode = params => (
    Fetch({
        url: '/trade/api/drug/hosp_drug/update_his_code',
        method: 'post',
        params
    })
);


// 采购单编制--创建采购单
export const purchasePlanOrderInsert = params => (
    Fetch({
        url: '/trade/api/deal/purchase/plan_order/insert',
        method: 'post',
        params
    })
);

// 采购单编制--批量加入采购单详情
export const purchasePlanOrderDetailBatchSave = params => (
    Fetch({
        url: '/trade/api/deal/purchase/plan_order_detail/batch_save',
        method: 'post',
        params
    })
);

// 采购单编制--批量修改采购单价格
export const purchasePlanOrderDetailBatchUpdate = params => (
    Fetch({
        url: '/trade/api/deal/purchase/plan_order_detail/batch_update',
        method: 'post',
        params
    })
);

// 采购单 --修改采购单
export const planOrderUpdateFetch = params => (
    Fetch({
        url: '/trade/api/deal/purchase/plan_order/update',
        method: 'post',
        params
    })
);

// 采采购单审核--修改采购单状态
export const planOrderAuditByOrderFetch = params => (
    Fetch({
        url: '/trade/api/deal/share/plan_order/audit_by_order',
        method: 'post',
        params
    })
);
// 采采购单审核--修改采购单状态
export const shippingAddressManageFindById = params => (
    Fetch({
        url: '/trade/api/deal/purchase/shipping_address_manage/find_by_id',
        method: 'get',
        params
    })
);

// 采采购单审核--修改采购单状态
export const purchaseDeliveryManageImportTradeDetailConfirm = params => (
    Fetch({
        url: '/trade/api/deal/purchase/delivery_manage/import_trade_detail_confirm',
        method: 'post',
        params
    })
);

// 耗材交易接口

// 获得机构信息
export const getOrgInfoDialog = params => (
    Fetch({
        url: '/mcs/api/base/org_info/get',
        method: 'get',
        params
    })
);

// 批量修改采购价
export const changePriceAll = params => (
    Fetch({
        url: '/mcs/api/goods/purchase/hosp_goods/edit_buy_price',
        method: 'post',
        params
    })
);
// 创建采购单
export const addPurchaseOrder = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order/add',
        method: 'post',
        params
    })
);
// 删除采购单
export const delPurchaseOrder = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order/del',
        method: 'post',
        params
    })
);
// 创建采购单补录
export const addPurchaseOrderTrack = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order/add_track',
        method: 'post',
        params
    })
);
// 获得采购单信息
export const getPurchaseOrderInfo = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order/get',
        method: 'get',
        params
    })
);
// 采购单编制--批量加入采购单明细
export const purchasePlanOrderBatchAdd = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order_detail/batch_add',
        method: 'post',
        params
    })
);
// 采购单编制--批量加入采购单明细
export const changePriceAllBatch = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order_detail/batch_edit_price',
        method: 'post',
        params
    })
);
// 采购单编制--复制采购单
export const purchasePlanOrderCopy = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order/copy',
        method: 'post',
        params
    })
);
// 采购单编制--修改采购单明细
export const purchasePlanOrderDetailEdit = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/plan_order_detail/edit',
        method: 'post',
        params
    })
);
// 通过联系地址获得对应的信息
export const getContactInfoByAddress = params => (
    Fetch({
        url: '/mcs/api/deal/purchase/shipping_address/get',
        params
    })
);
// 确定匹配单一规格
export const queryCheckPurchaseAddress = params => (
    Fetch({
        url: '/mcs/api/goods/purchase/hosp_goods/edit_match_to_spec',
        method: 'post',
        params
    })
);
// getContactInfoByAddress
// 确定匹配单一规格
export const editPurchaseHospGoods = params => (
    Fetch({
        url: '/mcs/api/goods/purchase/hosp_goods/addOrEdit',
        method: 'post',
        params
    })
);
