export default {
    userInfo: {},
    applyMeunList: [],
    pageTagsData: [],
    message: 0,
    taskType: [],
    hospData: {}, // 监控中心医院数据存储
    userModuleNum: 0, // 用户模块数量
    projectName: '', // 项目名称
    webName: '海西药械集中采购平台' // 网站名称

};
