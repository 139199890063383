/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-08 13:46:07
 * @Last Modified by: hejk
 * @Last Modified time: 2020-06-19 18:37:45
 * @Description: 申投诉管理
 */
import View from '@/components/view';

const ItemMaintain = () => import(/* webpackChunkName: "gl-item-maintain" */ '@/pages/aup/manage-org/proj-out/appeal-manage/item-maintain'); // 服务事项维护
const AppealDistribute = () => import(/* webpackChunkName: "gl-appeal-distribute" */ '@/pages/aup/manage-org/proj-out/appeal-manage/appeal-distribute'); // 申投诉任务分发
const AppealAccept = () => import(/* webpackChunkName: "gl-appeal-accept" */ '@/pages/aup/manage-org/proj-out/appeal-manage/appeal-accept'); // 申投诉受理  
const AppealConfirm = () => import(/* webpackChunkName: "gl-test-appeal-confirm" */ '@/pages/aup/manage-org/proj-out/appeal-manage/appeal-confirm'); // 申投诉确认
const Detail = () => import(/* webpackChunkName: "gl-appeal-detail" */ '@/pages/aup/manage-org/proj-out/appeal-manage/detail'); // 详情
const Deal = () => import(/* webpackChunkName: "gl-appeal-deal" */ '@/pages/aup/manage-org/proj-out/appeal-manage/deal'); // 处理/确认

export default [
    {
        path: 'appeal-manage',
        component: View,
        meta: {
            title: '申投诉管理'
        },
        children: [
            {
                path: 'item-maintain',
                component: ItemMaintain,
                meta: {
                    title: '服务事项维护'
                }
            },
            {
                path: 'appeal-distribute',
                component: AppealDistribute,
                meta: {
                    title: '申投诉任务分发'
                }
            },
            {
                path: 'appeal-accept',
                component: AppealAccept,
                meta: {
                    title: '申投诉受理'
                }
            },
            {
                path: 'appeal-confirm',
                component: AppealConfirm,
                meta: {
                    title: '申投诉确认'
                }
            },
            {
                path: 'appeal-accept/detail',
                component: Detail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'appeal-confirm/detail',
                component: Detail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'appeal-accept/deal',
                component: Deal,
                meta: {
                    title: '处理',
                    display: 'none'
                }
            },
            {
                path: 'appeal-confirm/deal',
                component: Deal,
                meta: {
                    title: '确认',
                    display: 'none'
                }
            }
        ]
    }
];
