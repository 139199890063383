/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-03-24 10:22:19
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-26 17:44:37
 * @Description: 配送管理（管理端）
 */
import View from '@/components/view';

const programme = () => import(/* webpackChunkName: "gl-programme" */ '@/pages/aup/manage-org/proj-inner/distribution-manage/programme');
const contract = () => import(/* webpackChunkName: "gl-contract" */ '@/pages/aup/manage-org/proj-inner/distribution-manage/contract');
const detail = () => import(/* webpackChunkName: "gl-distribution-detail" */ '@/pages/aup/components/distribution-detail');

const routes = [
    {
        path: 'distribution-manage',
        component: View,
        meta: {
            title: '配送管理'
        },
        children: [
            {
                path: 'contract',
                component: contract,
                meta: {
                    title: '合同查询'
                }
            },
            {
                path: 'contract/detail',
                component: detail,
                meta: {
                    title: '明细',
                    display: 'none'
                }
            },
            {
                path: 'programme',
                component: programme,
                meta: {
                    title: '配送方案查询'
                }
            }
        ]
    }
];

export default routes;
