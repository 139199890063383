/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:49:42
 * @Description: 管理端 入选结果公示路由
 */

import View from '@/components/view';

const routes = [
    {
        path: 'select-results',
        component: View,
        meta: {
            title: '入选结果公示'
        },
        children: [
            {
                path: 'select-result-manage',
                component: () => import(/* webpackChunkName: "gl-select-result-manage" */ '@/pages/aup/manage-org/proj-inner/select-results/select-result-manage'),
                meta: {
                    title: '入选结果管理'
                }
            }
        ]
    }
];

export default routes;
