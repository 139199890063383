/*
 * @Author: huanggk
 * @Email: huanggk@hxmec.com
 * @Date: 2020-08-12 16:59:28
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-01-28 14:19:03
 * @Description: Description
 */
import Vue from 'vue';
import {
    PageHeader,
    Button,
    Input,
    Icon,
    Badge,
    Menu,
    Table,
    Tabs,
    Form,
    DatePicker,
    Row,
    Col,
    Select,
    Divider,
    Alert,
    Breadcrumb,
    ConfigProvider,
    Carousel,
    Empty,
    Affix,
    Tag,
    FormModel,
    Checkbox,
    Anchor,
    Radio,
    Upload,
    Tooltip,
    Modal,
    // message,
    Collapse,
    Cascader,
    Popconfirm,
    InputNumber,
    Pagination,
    Space,
    Result,
    Spin,
    Drawer,
    Steps,
    Card,
    Dropdown,
    Popover,
    Progress
} from 'ant-design-vue';

export default function Init() {
    Vue.prototype.$confirm2 = Modal.confirm;
    Vue.prototype.$emptySimpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    Vue.use(PageHeader);
    Vue.use(Button);
    Vue.use(Input);
    Vue.use(Icon);
    Vue.use(Badge);
    Vue.use(Menu);
    Vue.use(Popover);
    Vue.use(Table);
    Vue.use(Tabs);
    Vue.use(Form);
    Vue.use(DatePicker);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Select);
    Vue.use(Divider);
    Vue.use(Alert);
    Vue.use(Breadcrumb);
    Vue.use(ConfigProvider);
    Vue.use(Carousel);
    Vue.use(Empty);
    Vue.use(Affix);
    Vue.use(Tag);
    Vue.use(FormModel);
    Vue.use(Checkbox);
    Vue.use(Anchor);
    Vue.use(Radio);
    Vue.use(Upload);
    Vue.use(Tooltip);
    Vue.use(Modal);
    Vue.use(Collapse);
    Vue.use(Cascader);
    Vue.use(Popconfirm);
    Vue.use(InputNumber);
    Vue.use(Pagination);
    Vue.use(Space);
    Vue.use(Result);
    Vue.use(Spin);
    Vue.use(Drawer);
    Vue.use(Steps);
    Vue.use(Card);
    Vue.use(Dropdown);
    Vue.use(Progress);
}
