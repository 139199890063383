/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-26 11:11:18
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-11-16 17:12:38
 * @Description: 注册证信息
 */
<template>
    <div>
        <bk-form
            ref="form"
            :form-config="formConfig" />
        <div class="empty-hei" />
        <bk-title label="资质信息" />
        <!-- <qualification ref="qualification"
                       :deploy="configration"
                       :is-edit="isEdit" /> -->
    </div>
</template>
<script>
import common from '@/api/aup/common';
// import qualification from '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/components/qualification'; 

export default {
    components: {
        // qualification
    },
    data() {
        const grid2 = {
            grid: 2
        };
        const noRequired = {
            rules: [
                { required: false }
            ]
        };
        const regItem = [
            {
                fieldName: '类型',
                name: 'regType',
                ...grid2
            },
            {
                fieldName: '注册证编号',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '注册证产品名称',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '发布日期',
                name: 'publicityTime',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '注册证有效期起',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '注册证有效期止',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '生产企业',
                ...grid2,
                ...noRequired
            }, {
                fieldName: '申报企业',
                ...grid2,
                disabled: false
            }, {
                fieldName: '备注1'
            }
        ];
        const regForm = common.getForm(regItem);
        return {
            regShow: false,
            isEdit: false,
            qualiConfigList: [],
            configration: [],
            formConfig: {
                readonly: true,
                data: [
                    {
                        data: regForm
                    }
                ]
            }
        };
    },
    mounted() {
        const { purPlatformProdCode, projectId } = this.$route.query;
        this.$fetch({
            url: '/hxupsc/api/prod/enroll/info/get/reg/cardBypurPlatformProdCode',
            params: {
                purPlatformProdCode,
                projectId
            },
            method: 'post'
        }).then(res => {
            if (res.code !== 0) {
                return;
            }
            const { regType, qualiConfigList } = res.data;
            this.qualiConfigList = qualiConfigList;
            this.setConfigration(regType);
            this.$refs.form.formConfig.results = res.data;
        });
    },
    methods: {
        /**
         * 选择类型时,对表单项及资质做对应切换
         * @param {Number} regType
         */
        setConfigration(regType) {
            const { data } = this.formConfig.data[0];
            if (regType !== 4) {
                data.splice(3, 1);
            } else {
                data.splice(5, 1);
                data.splice(4, 1);
            }
            this.configration = this.qualiConfigList[0].configData;
        }
    }
};
</script>
<style lang="less" scoped>
.empty-hei {
    height: 16px;
}
</style>
