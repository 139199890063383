/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-16 16:19:35
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-09-29 10:32:56
 * @Description: 微前端入口
 */


import Info from './base';
import routes from '../router/aup';

const VueLifecycles = Info({
    publicPath: 'hxupsc',
    routes: [
        ...routes,
        {
            path: '*',
            component: () => import(/* webpackChunkName: "not-found" */ '@/pages/not-found'),
            meta: {
                title: '404',
                noTag: true,
                display: 'none'
            }
        }
    ]
});

export const bootstrap = [
    VueLifecycles.bootstrap
];
// 项目启动后的钩子
export const mount = [
    VueLifecycles.mount
];
// 项目卸载的钩子
export const unmount = [
    VueLifecycles.unmount
];
