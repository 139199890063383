/*
 * @Author: zhuangmw
 * @Email: zhuangmw@guahao.com
 * @Date: 2019-12-22 13:17:27
 * @Last Modified by: hejk
 * @Last Modified time: 2020-07-31 13:46:48
 * @Description: 产品信息
 * @Route: /enterprise/inquery-manage/prod-info
 */
<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="注册证信息"
                         name="registInfo" />
            <el-tab-pane label="产品信息"
                         name="productInfo" />
            <el-tab-pane label="单一规格"
                         name="singleSpec" />
            <template v-if="isprice!=='1'">
                <el-tab-pane label="产品目录"
                             name="prodCata" />
                <!-- <el-tab-pane label="价格信息"
                             name="priceInfo" /> -->
            </template>
        </el-tabs>
        <component :is="activeName"
                   :prod-id="prodId" 
                   @setProdId="setProdId" />
    </div>
</template>
<script>
import registInfo from './components/regist-info'; 
import productInfo from './components/product-info'; 
import singleSpec from './components/single-spec';
import prodCata from './components/prod-cata';
import priceInfo from './components/price-info';

export default {
    components: { registInfo, productInfo, singleSpec, prodCata, priceInfo },
    data() {
        const { isprice } = this.$route.query;
        return {
            activeName: 'productInfo',
            prodId: '',
            isprice // 1:不显示产品目录、价格信息
        };
    },
    methods: {
        setProdId(id) {
            this.prodId = id;
        }
    }
};
</script>
