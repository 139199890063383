/*
 * @Author: tangliang
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-13 14:30:59
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:45:47
 * @Description: 申报结果公示
 */

import View from '@/components/view';

const ProjectManage = () => import(/* webpackChunkName: "gl-project-manage" */ '@/pages/aup/manage-org/proj-out/project-manage/index'); // 项目管理
export default [
    {
        path: 'project-manage',
        component: View,
        meta: {
            title: '项目管理'
        },
        children: [
            {
                path: 'index',
                component: ProjectManage,
                meta: {
                    title: '项目管理'
                }
            }
        ]
    }
];
