/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2020-06-29 13:57:20
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-08-23 09:48:43
 * @Description: 大屏路由
 */

import View from '@/components/view';
// 首轮解密大屏
const DecryptScreen = () => import(/* decrypt-screen */'@/pages/visual/decrypt-screen');

// 二轮解密大屏
const SecondDecryptScreen = () => import(/* second-decrypt-screen */'@/pages/visual/second-decrypt-screen');
// 二轮企业报价大屏
const SecondQuoteScreen = () => import(/* second-quote-screen */'@/pages/visual/second-quote-screen');

// 拟中选公示
const ProposedChosenResult = () => import(/* proposed-chosen-result */'@/pages/visual/proposed-chosen-result');
// 拟入围公示
const ProposedSelectedResult = () => import(/* proposed-selected-result */'@/pages/visual/proposed-selected-result');

// 开幕式
const Begin = () => import(/* begin-screen */'@/pages/visual/begin');
// 闭幕式
const End = () => import(/* end-screen */'@/pages/visual/end');


export default [
    {
        path: 'visual',
        component: View,
        meta: {
            title: '大屏'
        },
        children: [
            {
                path: 'decrypt-screen',
                component: DecryptScreen,
                meta: {
                    title: '首轮解密大屏'
                }
            },
            {
                path: 'second-decrypt-screen',
                component: SecondDecryptScreen,
                meta: {
                    title: '二轮解密大屏'
                }
            },
            {
                path: 'second-quote-screen',
                component: SecondQuoteScreen,
                meta: {
                    title: '二轮企业报价大屏'
                }
            },
            {
                path: 'proposed-chosen-result',
                component: ProposedChosenResult,
                meta: {
                    title: '拟中选公示'
                }
            },
            {
                path: 'proposed-selected-result',
                component: ProposedSelectedResult,
                meta: {
                    title: '拟入围公示'
                }
            },
            {
                path: 'begin',
                component: Begin,
                meta: {
                    title: '开幕式'
                }
            },
            {
                path: 'end',
                component: End,
                meta: {
                    title: '闭幕式'
                }
            }
        ]
    }
];
