/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-03-04 14:09:07
 * @Last Modified by: hejk
 * @Last Modified time: 2020-03-04 14:38:32
 * @Description: 产品目录
 */
<template>
    <div>
        <bk-form ref="form"
                 :form-config="formConfig" />
    </div>
</template>
<script>
import config from '@/pages/aup/common/config';

export default {
    mixins: [config],
    props: {
        prodId: {
            type: String,
            default: ''
        }
    },
    data() {
        const formConfig = [
            { name: '目录编号', grid: 1 }, 
            { name: '目录名称', grid: 1 }
        ];
        const formData = this.getSearchConfigInfo(formConfig);
        return {
            formConfig: {
                readonly: true,
                http: {
                    url: '/hxupsc/api/prod/enroll/info/getCatalogInfoById',
                    params: {
                        id: this.prodId
                    }
                },
                data: [
                    {
                        data: formData
                    }
                ]
            }
        };
    }
};
</script>
