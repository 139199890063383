/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-23 18:56:35
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:54:56
 * @Description: 直接挂网管理
 */
import View from '@/components/view';

const implementNet = () => import(/* webpackChunkName: "gl-implement-net" */ '@/pages/aup/manage-org/proj-inner/direct-net-manage/implement-net'); // 执行挂网管理
const resultManage = () => import(/* webpackChunkName: "gl-result-manage" */ '@/pages/aup/manage-org/proj-inner/direct-net-manage/result-manage'); // 直接挂网结果管理

export default [
    {
        path: 'direct-net-manage',
        component: View,
        meta: {
            title: '直接挂网管理'
        },
        children: [
            {
                path: 'implement-net',
                component: implementNet,
                meta: {
                    title: '执行挂网管理'
                }
            },
            {
                path: 'result-manage',
                component: resultManage,
                meta: {
                    title: '直接挂网结果管理'
                }
            }
        ]
    }
];
