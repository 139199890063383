/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-06-03 09:44:50
 * @Last Modified by: xujh
 * @Last Modified time: 2020-06-29 19:00:04
 * @Description: 动态调整方案二 路由
 * @Route: Route
 */

import View from '@/components/view';

const HangingNetDirectory = () => import(/* webpackChunkName: "hanging-net-directory" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/hanging-net-directory'); // 挂网目录
const AdjustPage = () => import(/* webpackChunkName: "adjust-page" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/adjust-page'); // 发起调整
const AdjustLook = () => import(/* webpackChunkName: "adjust-look" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/adjust-look'); // 查看详情页
const ProjectRelationShip = () => import(/* webpackChunkName: "project-relationship" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/project-relationship'); // 配送关系调整
const AdjustRelationship = () => import(/* webpackChunkName: "adjust-relationship" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/adjust-relationship'); // 申请调整
const LookAdjustLog = () => import(/* webpackChunkName: "look-adjust-log" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/look-adjust-log'); // 查看调整日志
const LookLogDetail = () => import(/* webpackChunkName: "look-log-detail" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/look-log-detail'); // 查看日志详情
const DispatchAdjustLog = () => import(/* webpackChunkName: "dispatch-adjust-log" */ '@/pages/aup/manage-org/proj-out/dynamic-regulation/dispatch-adjust-log'); // 配送日志详情
const QueskDetail = () => import(/* webpackChunkName: "quesk-detail" */ '@/pages/aup/manage-org/proj-out/appeal-manage/detail'); 
export default [
    {
        path: 'dynamic-regulation',
        component: View,
        meta: {
            title: '动态调整'
        },
        children: [
            {
                path: 'hanging-net-directory',
                component: HangingNetDirectory,
                meta: {
                    title: '挂网目录'
                }
            },
            {
                path: 'hanging-net-directory/adjust-page',
                component: AdjustPage,
                meta: {
                    title: '发起调整',
                    display: 'none'
                }
            },
            {
                path: 'hanging-net-directory/adjust-page/adjust-look',
                component: AdjustLook,
                meta: {
                    title: '查看日志详情',
                    display: 'none'
                }
            },
            {
                path: 'hanging-net-directory/adjust-page/queskDetail',
                component: QueskDetail,
                meta: {
                    title: '查看申诉单号',
                    display: 'none'
                }
            },
            {
                path: 'project-relationship',
                component: ProjectRelationShip,
                meta: {
                    title: '项目配送关系'
                }
            },
            {
                path: 'project-relationship/adjust-relationship',
                component: AdjustRelationship,
                meta: {
                    title: '调整页',
                    display: 'none'
                }
            },
            {
                path: 'hanging-net-directory/look-adjust-log',
                component: LookAdjustLog,
                meta: {
                    title: '查看调整日志',
                    display: 'none'
                }
            },
            {
                path: 'project-relationship/dispatch-adjust-log/look-log-detail',
                component: LookLogDetail,
                meta: {
                    title: '查看日志详情',
                    display: 'none'
                }
            },
            {
                path: 'project-relationship/dispatch-adjust-log',
                component: DispatchAdjustLog,
                meta: {
                    title: '查看配送调整日志',
                    display: 'none'
                }
            }
        ]
    }
];
