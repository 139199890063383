import pdfPreview from './pdf-preview';

const install = (Vue, options) => {
    const PdfViewer = Vue.extend(pdfPreview);
    const TargetPdf = new PdfViewer({
        propsData: options
    });
    
    TargetPdf.vm = TargetPdf.$mount();
    const domPdf = TargetPdf.vm.$el;
    document.body.appendChild(domPdf); // 将dom插入body

    Vue.prototype.$PdfPreview = url => {
        TargetPdf.vm.handleVisible(url);
    };
};

export default { install };