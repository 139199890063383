/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-14 15:39:35
 * @Last Modified by: hejk
 * @Last Modified time: 2020-09-08 16:45:06
 * @Description: 搜索配置
 */
import { catalogClass } from '@/api/audit';

export default {
    search: {
        注册证编号: {
            name: 'cardNo',
            type: 'input',
            label: '注册证编号',
            grid: 3
        },
        注册证名称: {
            name: 'cardName',
            type: 'input',
            label: '注册证名称',
            grid: 3
        },
        产品名称: {
            name: 'productName',
            type: 'input',
            label: '产品名称',
            grid: 3
        },
        规格: {
            name: 'standard',
            type: 'input',
            label: '规格',
            grid: 3
        },
        型号: {
            name: 'model',
            type: 'input',
            label: '型号',
            grid: 3
        },
        生产企业: {
            name: 'produceOrg',
            type: 'input',
            label: '生产企业',
            grid: 3
        },
        注册证有效期起: {
            name: 'validityStart',
            type: 'daterange',
            label: '注册证有效期起',
            grid: 3,
            extraFields: ['beginValidityStart', 'endValidityStart'],
            noSubmit: true
        },
        注册证有效期止: {
            name: 'validityEnd',
            type: 'daterange',
            label: '注册证有效期止',
            grid: 3,
            extraFields: ['beginValidityEnd', 'endValidityEnd'],
            noSubmit: true
        },
        注册证状态: {
            name: 'regCardStatus',
            type: 'select',
            label: '注册证状态',
            options: [
                {

                    label: '全部',
                    value: ''
                },
                {
                    label: '正常',
                    value: 0
                },
                {
                    label: '过期',
                    value: 1
                },
                {
                    label: '近效期',
                    value: 2
                }
            ],
            grid: 3
        },
        管理类别: {
            name: 'managementType',
            type: 'select',
            label: '管理类别',
            options: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '一类',
                    value: 1
                },
                {
                    label: '二类',
                    value: 2
                },
                {
                    label: '三类',
                    value: 3
                }
            ],
            grid: 3
        },
        编号: {
            name: 'singleNo',
            type: 'input',
            label: '编号',
            grid: 3
        },
        初审状态: {
            name: 'auditStatus',
            type: 'select',
            label: '初审状态',
            options: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '待提交',
                    value: 0
                },
                {
                    label: '待审核',
                    value: 1
                },
                {
                    label: '审核通过',
                    value: 3
                },
                {
                    label: '审核不通过',
                    value: 4
                }
            ],
            grid: 3
        },
        申请操作: {
            name: 'optType',
            type: 'select',
            label: '申请操作',
            options: [
                {
                    label: '企业新增',
                    value: 1
                },
                {
                    label: '企业修改',
                    value: 2
                }
                // {
                //     label: '企业撤回',
                //     value: 3
                // }
            ],
            grid: 3
        },
        初审审核人: {
            name: 'auditPerson',
            type: 'input',
            label: '初审审核人',
            grid: 3
        },
        最后修改时间: {
            name: 'gmtModified',
            type: 'daterange',
            label: '最后修改时间',
            grid: 3,
            extraFields: ['gmtModifiedStart', 'gmtModifiedEnd'],
            noSubmit: true
        },
        初审时间: {
            name: 'auditTime',
            type: 'daterange',
            label: '初审时间',
            grid: 3,
            extraFields: ['auditTimeStart', 'auditTimeEnd'],
            noSubmit: true
        },
        提交时间: {
            name: 'submitTime',
            type: 'daterange',
            label: '提交时间',
            grid: 3,
            extraFields: ['submitTimeStart', 'submitTimeEnd'],
            noSubmit: true
        },
        一证多选: {
            name: 'oneRegMoreChoiceFlag',
            type: 'select',
            label: '一证多选',
            options: [
                {
                    label: '是',
                    value: 1
                },
                {
                    label: '否',
                    value: 2
                }
            ],
            grid: 3
        },
        只修改注册证基本信息: {
            name: 'onlyModifyRegFlag',
            type: 'select',
            label: '只修改注册证基本信息',
            options: [
                {
                    label: '是',
                    value: 1
                },
                {
                    label: '否',
                    value: 2
                }
            ],
            grid: 3
        },
        企业类型: {
            name: 'orgType',
            type: 'select',
            label: '企业类型',
            options: [
                {
                    label: '医疗器械（医用耗材）生产企业',
                    value: 1
                },
                {
                    label: '医疗器械（医用耗材）代理企业',
                    value: 2
                },
                {
                    label: '医疗器械（医用耗材）配送企业',
                    value: 3
                },
                {
                    label: '医疗器械（医用耗材）生产及代理企业',
                    value: 4
                }

            ],
            grid: 3
        },
        企业名称: {
            name: 'orgInfoName',
            type: 'input',
            label: '企业名称',
            grid: 3
        },
        提交人: {
            name: 'submitPerson',
            type: 'input',
            label: '提交人',
            grid: 3
        },
        被授权人姓名: {
            name: 'authorizedPersonName',
            type: 'input',
            label: '被授权人姓名',
            grid: 3
        },
        企业全称: {
            name: 'orgInfoName',
            label: '企业全称',
            type: 'input',
            grid: 3
        },
        公示时间: {
            name: 'publicityTime',
            type: 'daterange',
            label: '公示时间',
            grid: 3,
            extraFields: ['publicityTimeStart', 'publicityTimeEnd'],
            noSubmit: true
        },
        申报企业: {
            name: 'orgName',
            label: '申报企业',
            type: 'input',
            grid: 3
        },
        类型: {
            name: 'regType',
            label: '类型',
            type: 'select',
            grid: 3,
            options: [
                {
                    label: '第一类医疗器械产品',
                    value: 1
                },
                {
                    label: '二类医疗器械产品',
                    value: 2
                },
                {
                    label: '三类医疗器械产品',
                    value: 5
                },
                {
                    label: '消毒类产品',
                    value: 3
                },
                {
                    label: '分类界定产品',
                    value: 4
                }
            ]
        },
        分类名称: {
            name: 'catalogParentNum',
            label: '分类名称',
            type: 'select',
            http: {
                url: catalogClass,
                method: 'get',
                onSuccess: res => [
                    {
                        catalogName: '全部',
                        catalogNum: ''
                    },
                    ...res.data
                ]
            },
            props: {
                value: 'catalogNum',
                label: 'catalogName'
            },
            grid: 3
        },
        目录名称: {
            name: 'catalogName',
            label: '目录名称',
            type: 'input',
            grid: 3
        },
        分类编号: {
            name: 'pcatalogNum',
            label: '分类编号',
            type: 'input',
            grid: 3
        },
        目录编号: {
            name: 'catalogNum',
            label: '目录编号',
            type: 'input',
            grid: 3
        },
        复审审核人: {
            name: 'reAuditPerson',
            type: 'input',
            label: '复审审核人',
            grid: 3
        },
        复审时间: {
            name: 'reauditTime',
            type: 'daterange',
            label: '复审时间',
            grid: 3,
            extraFields: ['reAuditTimeStart', 'reAuditTimeEnd'],
            noSubmit: true
        },
        复审状态: {
            name: 'reAuditStatus',
            type: 'select',
            label: '复审状态',
            options: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '待审核',
                    value: '1'
                },
                {
                    label: '审核通过',
                    value: 3
                },
                {
                    label: '审核不通过',
                    value: 4
                }
            ],
            grid: 3
        },
        产品编号: {
            name: 'productCode',
            label: '产品编号',
            type: 'input',
            grid: 3
        },
        通用名: {
            name: 'commonName',
            label: '通用名',
            type: 'input',
            grid: 3
        },
        医用耗材代码: {
            name: 'nationalCode',
            label: '医用耗材代码',
            type: 'input',
            grid: 3
        },
        一级分类: {
            name: 'levelOne',
            label: '一级分类',
            type: 'input',
            grid: 3
        },
        二级分类: {
            name: 'levelTwo',
            label: '二级分类',
            type: 'input',
            grid: 3
        },
        三级分类: {
            name: 'levelThree',
            label: '三级分类',
            type: 'input',
            grid: 3
        },
        单一规格产品编码: {
            name: 'singleProductCode',
            label: '单一规格产品编码',
            type: 'input',
            grid: 3
        },
        医疗器械唯一标识信息编码: {
            name: 'singleCode',
            label: '医疗器械唯一标识信息编码',
            type: 'input',
            grid: 3
        },
        prod复审状态: {
            name: 'secondAuditStatus',
            type: 'select',
            label: '复审状态',
            options: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '待审核',
                    value: '1'
                },
                {
                    label: '审核通过',
                    value: 3
                },
                {
                    label: '审核不通过',
                    value: 4
                }
            ],
            grid: 3
        }

    }
};
