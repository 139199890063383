/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-03 12:56:28
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-08-08 14:51:45
 * @Description: 微前端方案
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
// import routes from '../router';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config';
import '@/styles/custom';
import VueSwiper from 'vue-awesome-swiper';
import { getUrlParame } from '@/utils/utils';
import PdfPreview from '@/components/pdf-preview/index';

import AntdInit from './antd-init';
import 'ant-design-vue/dist/antd.css';
import '@/styles/index';

AntdInit();

const env = process.env.NODE_ENV;

function init({ publicPath = 'trans', routes = {} }, callback) {
    Vue.config.productionTip = false;
    Vue.config.silent = env !== 'development';
    let menuObj = {}; // 项目内菜单
    const router = new VueRouter({
        mode: 'history',
        // mode:'hash',
        routes
    });
    Vue.use(VueRouter);
    Vue.use(Element);
    Vue.use(PdfPreview);
    Vue.use(VueSwiper);

    if (callback) callback(Vue, router);
    // Vue.prototype.$fetch = option => (window.App && window.App.$root.fetch ? window.App.$root.fetch(option) : fetch(option));
    // Vue.prototype.$bkConfig = configs;
    const store = env === 'development' ? storeData : window.Store;
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="hxupsc" class="global-wrapper">
            <router-view></router-view>
        </div>,
        store,
        router,
        computed: {
            bkConfig: () => configs
            
        },
        watch: {
            $route() {
                // 微前端 项目内外处理
                this.loadPojectM();
            }
        },
        mounted() {
            // 微前端 项目内外处理
            menuObj = {};
            this.loadPojectM();
        },
        methods: {
            fetch: config => fetch(config),
            loadPojectM() {
                // 微前端 项目内外处理
                if (window.Store) {
                    const itemId = getUrlParame('itemId');
                    const menu = menuObj[itemId];
                    if (menu) {
                        window.Store.commit('GET_APPLY_MENU', menu);
                    } else if (itemId) {
                        window.App.$root.fetch({
                            url: '/hxupsc/api/project/menu/inner',
                            params: {
                                projectId: itemId,
                                systemId: localStorage.getItem('system_id')
                            }
                        }).then(res => {
                            if (res) {
                                menuObj[itemId] = res.data;
                                window.Store.commit('GET_APPLY_MENU', res.data);
                            }
                        });
                    } else {
                        const { outer } = menuObj;
                        if (outer) {
                            window.Store.commit('GET_APPLY_MENU', outer);
                        } else {
                            window.App.$root.fetch({
                                url: '/hxupsc/api/project/menu/outer',
                                params: {
                                    systemId: localStorage.getItem('system_id')
                                }
                            }).then(res => {
                                if (res) {
                                    menuObj.outer = res.data;
                                    window.Store.commit('GET_APPLY_MENU', res.data);
                                }
                            });
                        }
                    }
                }
            }
        }

    };
    // 区分项目内，项目外菜单
    router.beforeEach((to, from, next) => {
        const { path: toPath, query } = to;
        const { itemId, itemType } = query;
        const { itemId: fromItemId, itemType: fromItemType } = from.query;
        const token = localStorage.getItem('token');
        // console.log('itemId::', fromItemId, itemId);
        localStorage.setItem('rootProductId', itemId);
        const loginPath = ['/login', '/portal', '/hxupsc'];
        // 项目外校验
        const outer = 'proj-out';
        // 回到登录
        if (!token && to.path !== loginPath) {
            next({
                path: loginPath,
                query: {
                    path: to.fullPath
                }
            });
        }
        // 判断是否需要itemId 项目内用
        const notRequireId = false;
        // 执行跳转
        if (toPath.indexOf(outer) === -1 && !notRequireId && !itemId && !itemType && fromItemId && loginPath.indexOf(toPath) === -1) {
            next({
                path: toPath,
                query: {
                    itemId: fromItemId,
                    itemType: fromItemType,
                    ...to.query
                }
            });
        } else {
            next();
        }
    });
    let vueLifecycles = {}; // 微前端

    if (env === 'development') {
        window.App = new Vue(appOptions).$mount('#app'); // 手动挂载
    } else {
        setPublicPath(publicPath); // 注册应用
        store.commit('SAVE_ROUTE_DATA', routes); // 保存路由信息到全局
        vueLifecycles = singleSpaVue({
            Vue,
            appOptions
        });
    }


    return vueLifecycles;
}

// 项目启动的钩子
export default init;
