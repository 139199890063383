/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-08-10 10:48:35
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-07-12 10:25:24
 * @Description: 申报审核
 */

import View from '@/components/view';

const orgAudit = () => import(/* webpackChunkName: "org-audit" */ '@/pages/aup/manage-org/proj-inner/declare/org-audit'); // 企业资料审核
const orgChangeLog = () => import(/* webpackChunkName: "org-change-log" */ '@/pages/aup/manage-org/proj-inner/declare/org/change-log'); // 企业变更记录
const orgDetail = () => import(/* webpackChunkName: "org-detail" */ '@/pages/aup/manage-org/proj-inner/declare/org/detail'); // 企业详情
const orgContrast = () => import(/* webpackChunkName: "org-contrast" */ '@/pages/aup/manage-org/proj-inner/declare/org/contrast'); // 企业比对
const orgInfoAudit = () => import(/* webpackChunkName: "org-info-audit" */ '@/pages/aup/manage-org/proj-inner/declare/org/audit'); // 企业资料审核

const prodAudit = () => import(/* webpackChunkName: "prod-audit" */ '@/pages/aup/manage-org/proj-inner/declare/prod-audit'); // 中药材产品审核
const prodChangeLog = () => import(/* webpackChunkName: "prod-change-log" */ '@/pages/aup/manage-org/proj-inner/declare/prod/change-log'); // 产品变更记录
const prodDetail = () => import(/* webpackChunkName: "prod-detail" */ '@/pages/aup/manage-org/proj-inner/declare/prod/detail'); // 产品详情
const prodContrast = () => import(/* webpackChunkName: "prod-contrast" */ '@/pages/aup/manage-org/proj-inner/declare/prod/contrast'); // 产品比对
const prodInfoAudit = () => import(/* webpackChunkName: "prod-info-audit" */ '@/pages/aup/manage-org/proj-inner/declare/prod/audit'); // 产品资料审核
const sampleAudit = () => import(/* webpackChunkName: "sample-audit" */ '@/pages/aup/manage-org/proj-inner/declare/sample-audit'); // 样品信息审核
const sampleDetail = () => import(/* webpackChunkName: "sample-detail" */ '@/pages/aup/manage-org/proj-inner/declare/sample/detail'); // 样品产品详情
const resetAudit = () => import(/* webpackChunkName: "reset-audit" */ '@/pages/aup/manage-org/proj-inner/declare/reset-audit'); // 重置审核状态

const sampleReceive = () => import(/* webpackChunkName: "sample-receive" */ '@/pages/aup/manage-org/proj-inner/declare/sample/receive'); // 样品接收
const sampleReceiveDetail = () => import(/* webpackChunkName: "sample-receive-detail" */ '@/pages/aup/manage-org/proj-inner/declare/sample/receive-detail'); // 样品接收
const receipt = () => import(/* webpackChunkName: "sample-receive-receipt" */ '@/pages/aup/manage-org/proj-inner/declare/sample/receive-receipt'); // 样品接收回执单
const sampleReviewResults = () => import(/* webpackChunkName: "sample-review-results" */ '@/pages/aup/manage-org/proj-inner/declare/sample/review-results'); // 样品评审结果管理
const sampleReviewResultsPrint = () => import(/* webpackChunkName: "sample-review-results-print" */ '@/pages/aup/manage-org/proj-inner/declare/sample/review-results-print'); // 样品评审结果管理
const sampleOrgSendInfo = () => import(/* webpackChunkName: "sample-org-send-info" */ '@/pages/aup/manage-org/proj-inner/declare/sample/org-send-info'); // 企业寄送样品信息
const sampleOrgMaintainInfo = () => import(/* webpackChunkName: "sample-org-maintain-info" */ '@/pages/aup/manage-org/proj-inner/declare/sample/org-maintain-info'); // 企业寄送样品信息

const DeliveryQualityAudit = () => import(/* webpackChunkName: "delivery-quality-audit" */ '@/pages/aup/manage-org/proj-inner/declare/delivery-quality/index'); // 配送资质审核
const DeliveryQualityAuditDetail = () => import(/* webpackChunkName: "delivery-quality-audit-detail" */ '@/pages/aup/manage-org/proj-inner/declare/delivery-quality/detail'); // 配送资质审核/详情

const AuditProgressQuery = () => import(/* webpackChunkName: "audit-progress-query" */ '@/pages/aup/manage-org/proj-inner/declare/audit-progress-query'); // 配送资质审核/详情

const routes = [
    {
        path: 'declare',
        component: View,
        meta: {
            title: '申报审核'
        },
        children: [
            {
                path: 'org-audit',
                component: orgAudit,
                meta: {
                    title: '企业报名资质审核'
                }
            },
            {
                path: 'org-audit/change-log',
                component: orgChangeLog,
                meta: {
                    title: '变更记录',
                    display: 'none'
                }
            },
            {
                path: 'org-audit/detail',
                component: orgDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'org-audit/change-log/contrast',
                component: orgContrast,
                meta: {
                    title: '资料比对',
                    display: 'none'
                }
            },
            {
                path: 'org-audit/audit',
                component: orgInfoAudit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit',
                component: prodAudit,
                meta: {
                    title: '品种目录报名审核'
                }
            },
            {
                path: 'sample-audit',
                component: sampleAudit,
                meta: {
                    title: '样品信息审核'
                }
            },
            {
                path: 'sample-receive',
                component: sampleReceive,
                meta: {
                    title: '样品接收管理',
                    display: 'block'
                }
            },
            {
                path: 'sample-receive/receipt',
                component: receipt,
                meta: {
                    title: '样品接收回执单',
                    display: 'none'
                }
            },
            {
                path: 'sample-receive/detail',
                component: sampleReceiveDetail,
                meta: {
                    title: '样品接收',
                    display: 'none'
                }
            },
            {
                path: 'sample-review-results',
                component: sampleReviewResults,
                meta: {
                    title: '样品评审结果'
                }
            },
            {
                path: 'sample-review-results/print',
                component: sampleReviewResultsPrint,
                meta: {
                    title: '打印评审结果',
                    display: 'none'
                }
            },
            {
                path: 'org-send-sampleInfo',
                component: sampleOrgSendInfo,
                meta: {
                    title: '企业寄送样品信息'
                }
            },
            {
                path: 'org-sample-maintain-info',
                component: sampleOrgMaintainInfo,
                meta: {
                    title: '企业样品维护信息'
                }
            },
            {
                path: 'org-sample-maintain-info/detail',
                component: sampleDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/change-log',
                component: prodChangeLog,
                meta: {
                    title: '变更记录',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/detail',
                component: prodDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'sample-audit/detail',
                component: sampleDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/change-log/contrast',
                component: prodContrast,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/audit',
                component: prodInfoAudit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'reset-audit',
                component: resetAudit,
                meta: {
                    title: '重置审核状态',
                    display: 'none'
                }
            },
            {
            
                path: 'delivery-quality-audit',
                component: DeliveryQualityAudit,
                meta: {
                    title: '配送企业资质审核'
                }
            },
            {
                path: 'delivery-quality-audit/detail',
                component: DeliveryQualityAuditDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'delivery-quality-audit/audit',
                component: DeliveryQualityAuditDetail,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'audit-progress-query',
                component: AuditProgressQuery,
                meta: {
                    title: '企业报名审核进度查询'
                }
            }
        ]
    }
];

export default routes;
