/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-01-28 14:52:25
 * @Description: 企业端-项目外
 * @Route: Route
 */
import View from '@/components/view';
import SystemManage from './system-manage';
import ShareResults from './share-results';
import DeclareManage from './declare-manage/index';
import DeclareResult from './declare-result/index';
import ProMan from './project-manage';
import CatalogManage from './catalog-manage';
import AppealManage from './appeal-manage';
// import ExpertManage from './expert-manage';
import DynamicAdjust from './dynamic-adjust';
import DistributionRelationship from './distribution-relationship';
import DynamicRegulation from './dynamic-regulation';
import purchasingProject from './purchasing';// 集采项目管理

// const Index = () => import('@/pages/aup/enterprise-org/proj-out/index');
const routes = [
    {
        path: 'proj-out',
        component: View,
        meta: {
            title: '项目外'
        },
        children: [
            // {
            //     path: 'index',
            //     component: Index,
            //     meta: {
            //         title: '工作台'
            //     }
            // },
            ...ProMan,
            ...SystemManage,
            ...ShareResults,
            ...DeclareManage,
            ...DeclareResult,
            ...CatalogManage,
            ...AppealManage,
            ...DistributionRelationship,
            // ...ExpertManage,
            ...DynamicRegulation,
            ...DynamicAdjust,
            ...purchasingProject
        ]
    }
];

export default routes;
