/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 10:41:10
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-09-27 10:49:27
 * @Description: 耗材
 */
import View from '@/components/view';
import ProdInfo from '@/pages/aup/components/result-public/prod-info';
// import Hosp from './hosp'; // 医院端路由
import Manage from './manage'; // 管理端路由
import Visual from './visual'; // 管理端路由

export default [{
    path: '/hxupsc',
    component: View,
    meta: {
        title: '耗材集中采购平台',
        noTag: true
    },
    children: [
        ...Manage,
        // ...Hosp,
        ...Visual,
        {
            path: 'prod-info',
            component: ProdInfo,
            meta: {
                title: '产品详情'
            }
        }
    ]
}];
