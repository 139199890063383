/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-04 15:26:28
 * @Last Modified by: hejk
 * @Last Modified time: 2020-07-24 17:40:16
 * @Description: 配置方法提取
 */

// 引入正则表达式文件
import { intPointReg } from '@/utils/regular';
import { deepCopy } from '@/utils/utils';

export default {
    data() {
        return {

        };
    },
    methods: {
        /**
         * 过滤出需要的config,data数据
         * @param {Array} keyArr 一个保存key值得集合
         * @param {Object} obj 用于筛选的对象
         * @param {Number} filedFrom  1：表单
         */
        getFilterArr(keyArr, obj, filedFrom) {
            const data = [];
            keyArr.forEach(item => {
                if (typeof item === 'string') {
                    if (obj[item]) {
                        obj[item].clearable = true;// 统一可以清空
                        if (filedFrom === 1 && (obj[item].type === 'select' || obj[item].type === 'radio') && Array.isArray(obj[item].options) && obj[item].options[0].value === '') {
                            obj[item].options.splice(0, 1);
                        }
                        data.push(obj[item]);
                    } else if (item !== '') {
                        // 未匹配对象处理
                        data.push({
                            label: `${item}-未匹配`,
                            name: item
                        });
                    }
                } else {
                    const currentObj = obj[item.name];
                    if (currentObj) {
                        const keys = deepCopy(Object.keys(item));
                        keys.forEach(key => {
                            if (key !== 'name') {
                                currentObj[key] = item[key];
                            }
                            if (key === 'setName') {
                                currentObj.name = item[key];
                            }
                        });
                        currentObj.clearable = true;// 统一可以清空
                        if (filedFrom === 1 && (currentObj.type === 'select' || currentObj.type === 'radio') && Array.isArray(currentObj.options) && currentObj.options.length > 0 && currentObj.options[0].value === '') {
                            currentObj.options.splice(0, 1);
                        }
                        data.push(currentObj);
                    } else if (item.name !== '') {
                        // 未匹配对象处理
                        data.push({
                            label: `${item.name}-未匹配`,
                            name: item.name
                        });
                    }
                }
            });
            return data;
        },
        /**
         * 获取dialogConfig配置数据
         * @param {Object} data dialog配置数据
         * @param {String} name 要查找的dialog的name
         */
        getDialogConfiData(dialog, name) {
            if (dialog && name) {
                return dialog.data.find(item => item.name === name);
            }
            return {};
        },
        /**
         * 深度拷贝一个数据
         * @param {Array/Object} data 需要深度拷贝的数据
         */
        copyData(data) {
            if (data) {
                return JSON.parse(JSON.stringify(data));
            }
            return '';
        },
        /**
         * 返回上一页的操作
         */
        onBack() {
            window.history.back(-1);
        },
        /**
         * 消息提示操作
         * @param {String} messageType 饿了吗消息类型
         * @param {*} messageInfo 消息信息
         */
        openMessage(messageType, messageInfo) {
            return new Promise(resolve => {
                const type = messageType || 'success';
                const message = messageInfo || '操作成功';
                this.$message({
                    type,
                    message,
                    duration: 1000,
                    onClose: () => {
                        resolve();
                    }
                });
            });
        },
        /**
         * 校验必填项
         * @param {String} message 提示消息
         * @param {Boolean} type 是否替换消息,默认false会进行消息拼接,true为全覆盖
         */
        rulesRequired(message, type) {
            const bool = type || false;
            return [
                {
                    required: true, message: bool ? message : `${message}不能为空`
                }
            ];
        },
        /**
        *判断是否是数字
        *
        * */
        isRealNum(val) {
            if (intPointReg.test(val)) {
                return true;
            }
            return false;
        },
        /**
         * 校验正整数
         */
        rulesNumber(rule, value, callback) {
            if (value) {
                if (intPointReg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入数字'));
                }
            } else {
                callback();
            }
            return false;
        },
        /**
         * 匹配数组参数,找出data合适的数据组成一个新的数组
         * @param {Array} params 需要匹配的数组参数
         * @param {Array} data 用于查找的数组
         */
        matchingParamsArray(params, data) {
            const arr = [];
            params.forEach(item => {
                arr.push(data[item]);
            });
            return arr;
        },
        /**
         * 获取当前窗口的宽度
         */
        getViewWidth(name) {
            return this.$refs[name].offsetWidth;
        },
        /**
         * 获取idList的操作
         */
        getIdList(arr, name) {
            const keyName = name || 'id';
            const list = [];
            arr.forEach(item => {
                list.push(item[keyName]);
            });
            return list;
        },
        /**
         * 当前页面数据合计
         * @param {*} data 要遍历的数据
         * @param {*} name 需要统计的字段
         * @param {*} name 需要统计的字段
         */
        currentPageNumber(data, name, num) {
            let statistical = 0;
            data.forEach(item => {
                if (num) {
                    statistical += (Number(item[name]) || 0) * Number((item[num])); 
                } else {
                    statistical += Number(item[name]) || 0;
                }   
            });
            if (statistical === 0) {
                return 0;
            }
            return statistical.toFixed(4);
        }
    }
};
