/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-03 11:16:22
 * @Last Modified by: hejk
 * @Last Modified time: 2019-12-03 11:30:21
 * @Description: 产品类别
 */
export default [
    {
        value: '6801',
        label: '基础外科手术器械'
    }, {
        value: '6802',
        label: '显微外科手术器械'
    },
    {
        value: '6803',
        label: '神经外科手术器械'
    },
    {
        value: '6804',
        label: '眼科手术器械'
    },
    {
        value: '6805',
        label: '耳鼻喉科手术器械'
    },
    {
        value: '6806',
        label: '口腔科手术器械'
    },
    {
        value: '6807',
        label: '胸腔心血管外科手术器械'
    },
    {
        value: '6808',
        label: '腹部外科手术器械'
    },
    {
        value: '6809',
        label: '泌尿肛肠外科手术器械'
    },
    {
        value: '6810',
        label: '矫形外科（骨科）手术器械'
    },
    {
        value: '6812',
        label: '妇产科用手术器械'
    },
    {
        value: '6813',
        label: '计划生育手术器械'
    },
    {
        value: '6815',
        label: '注射穿刺器械'
    },
    {
        value: '6816',
        label: '烧伤(整形)科手术器械'
    },
    {
        value: '6820',
        label: '普通诊察器械'
    },
    {
        value: '6821',
        label: '医用电子仪器设备'
    },
    {
        value: '6822',
        label: '医用光学器具、仪器及内窥镜设备'
    },
    {
        value: '6823',
        label: '医用超声仪器及有关设备'
    },
    {
        value: '6824',
        label: '医用激光仪器设备'
    },
    {
        value: '6825',
        label: '医用高频仪器设备'
    },
    {
        value: '6826',
        label: '物理治疗及康复设备'
    },
    {
        value: '6827',
        label: '中医器械'
    },
    {
        value: '6828',
        label: '医用磁共振设备'
    },
    {
        value: '6830',
        label: '医用X射线设备'
    },
    {
        value: '6831',
        label: '医用X射线附属设备及部件'
    },
    {
        value: '6832',
        label: '医用高能射线设备'
    },
    {
        value: '6833',
        label: '医用核素设备'
    },
    {
        value: '6834',
        label: '医用射线防护用品、装置'
    },
    {
        value: '6840',
        label: '临床检验分析仪器'
    },
    {
        value: '6841',
        label: '医用化验和基础设备器具'
    },
    {
        value: '6845',
        label: '体外循环及血液处理设备'
    },
    {
        value: '6846',
        label: '植入材料和人工器官'
    },
    {
        value: '6854',
        label: '手术室、急救室、诊疗室设备及器具'
    },
    {
        value: '6855',
        label: '口腔科设备及器具'
    },
    {
        value: '6856',
        label: '病房护理设备及器具'
    },
    {
        value: '6857',
        label: '消毒和灭菌设备及器具'
    },
    {
        value: '6858',
        label: '医用冷疗、低温、冷藏设备及器具'
    },
    {
        value: '6863',
        label: '口腔科材料'
    },
    {
        value: '6864',
        label: '医用卫生材料及敷料'
    },
    {
        value: '6865',
        label: '医用缝合材料及粘合剂'
    },
    {
        value: '6866',
        label: '医用高分子材料及制品'
    },
    {
        value: '6870',
        label: '软件'
    },
    {
        value: '6877',
        label: '介入器材'
    }
];
