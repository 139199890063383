/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-03-03 19:13:20
 * @Description: 企业端-项目内
 * @Route: Route
 */
import View from '@/components/view';
import declare from '@/router/aup/manage/inner/declare';
import VolumeManage from './volume-manage';
import QuotationManage from './quotation-manage';
import ProjectConfig from './project-config';
import selectResults from './select-results';
import biddingManage from './bidding-manage';
import directManage from './direction-manage';
import negotManage from './negotiate-manage';
import prodRegister from './prod-register';
import statistics from './statistics';
import distributionResult from './distribution-manage';
import directionManage from './directory-manage';
import historyPurchaseManage from './history-purchase-manage';
import priceLimitPub from './price-limit-pub';
import directNetManage from './direct-net-manage';
import scoreManage from './score-manage';
import reAuditScore from './reAudit-score';
// const Index = () => import('@/pages/aup/enterprise-org/proj-out/index');
const routes = [
    {
        path: 'proj-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            // {
            //     path: 'index',
            //     component: Index,
            //     meta: {
            //         title: '工作台'
            //     }
            // },
            ...reAuditScore,
            ...scoreManage,
            ...VolumeManage,
            ...QuotationManage,
            ...ProjectConfig,
            ...selectResults,
            ...biddingManage,
            ...directManage,
            ...negotManage,
            ...prodRegister,
            ...statistics,
            ...distributionResult,
            ...directionManage,
            ...historyPurchaseManage,
            ...priceLimitPub,
            ...directNetManage,
            ...declare,
            {
                path: 'catalogue/index',
                component: () => import('@/pages/aup/manage-org/proj-inner/catalogue'),
                meta: {
                    title: '目录管理'
                }
            },
            {
                path: 'purchasing/decrypt',
                component: () => import('@/pages/aup/manage-org/proj-inner/purchasing/decrypt'),
                meta: {
                    title: '解密配置'
                }
            }
        ]
    }
];

export default routes;
