/*
 * @Author: lina
 * @Email: lina5@guahao.com
 * @Date: 2020-05-11 19:18:39
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-07 10:53:26
 * @Description: 挂网状态与价格调整
 * @Route: Route
 */

import View from '@/components/view';

const DynamicAdjust = () => import(/* webpackChunkName: "DynamicAdjust" */ '@/pages/aup/manage-org/proj-out/dynamic-adjust/adjust-list'); // 动态调整列表
const Adjust = () => import(/* webpackChunkName: "adjust" */ '@/pages/aup/manage-org/proj-out/dynamic-adjust/adjust'); // 动态调整列表
const Confirm = () => import(/* webpackChunkName: "adjust" */ '@/pages/aup/manage-org/proj-out/dynamic-adjust/confirm'); // 动态调整列表
const LogDetail = () => import(/* webpackChunkName: "adjust" */ '@/pages/aup/manage-org/proj-out/dynamic-adjust/log-detail'); // 动态调整列表
const Detail = () => import(/* webpackChunkName: "adjust" */ '@/pages/aup/manage-org/proj-out/dynamic-adjust/detail'); // 动态调整列表
export default [
    {
        path: 'dynamic-adjust',
        component: View,
        meta: {
            title: '挂网状态与价格调整'
        },
        children: [
            {
                path: 'list',
                component: DynamicAdjust,
                meta: {
                    title: '挂网状态与价格调整'
                }
            },
            {
                path: 'adjust',
                component: Adjust,
                meta: {
                    title: '发起调整',
                    display: 'none'
                }
            },
            {
                path: 'confirm',
                component: Confirm,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'detail',
                component: Detail,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'log-detail',
                component: LogDetail,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];
