/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:53:22
 * @Description: 谈判管理
 */

import View from '@/components/view';
import fetch from '@/utils/fetch';

const negotiateManage = () => import(/* webpackChunkName: "gl-negotiate-manage" */ '@/pages/aup/manage-org/proj-inner/negotiate-manage/index');
const Expert = () => import(/* webpackChunkName: "gl-negotiate-manage-expert" */ '@/pages/aup/manage-org/proj-inner/negotiate-manage/expert');
const negotiationsManage = () => import(/* webpackChunkName: "gl-negotiations-manage" */ '@/pages/aup/manage-org/proj-inner/negotiate-manage/negotiations-manage');


const routes = [
    {
        path: 'negotiate',
        component: View,
        meta: {
            title: '谈判管理'
        },
        children: [
            {
                path: 'manage',
                component: negotiateManage,
                meta: {
                    title: '谈判解密-已开始',
                    display: 'none'
                }
            },
            {
                path: 'decrypt-before',
                component: () => import('@/pages/common/notice'),
                meta: {
                    title: '谈判解密'
                },
                beforeEnter(to, from, next) {
                    fetch({
                        url: '/hxupsc/api/enroll/round/negotiation/summary',
                        method: 'post',
                        params: {
                            projectId: to.query.itemId || 1
                        }
                    }).then(res => {
                        const { startTime, endTime, status } = res.data;
                        to.query.startTime = startTime;
                        to.query.endTime = endTime;
                        if (status === 0) {
                            to.query.title = '谈判解密未启动，请耐心等待！';
                            next();
                        } else if (status === 3) {
                            to.query.title = '谈判确认已开始，不能进行解密！';
                            next();
                        } else {
                            next({
                                path: '/hxupsc/manage-org/proj-inner/negotiate/manage',
                                query: from.query
                            });
                        }
                    });
                }
            },
            {
                path: 'expert',
                component: Expert,
                meta: {
                    title: '专家谈判'
                }
            },
            {
                path: 'negotiations-manage',
                component: negotiationsManage,
                meta: {
                    title: '谈判结果管理'
                }
            }
        ]
    }
];

export default routes;
