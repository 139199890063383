/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-03-31 13:54:14
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:48:19
 * @Description: 目录库管理
 */


import View from '@/components/view';

const DirectoryBase = () => import(/* webpackChunkName: "gl-directory-base" */ '@/pages/aup/manage-org/proj-out/catalog-manage/directory-base'); // 目录基础库

export default [
    {
        path: 'catalog-manage',
        component: View,
        meta: {
            title: '目录库管理'
        },
        children: [
            {
                path: 'directory-base',
                component: DirectoryBase,
                meta: {
                    title: '目录基础库'
                }
            }
        ]
    }
];
