/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-10 18:04:09
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-11-12 18:29:27
 * @Description: 报价管理
 */

import View from '@/components/view';
// import fetch from '@/utils/fetch';

const ProductQuotation = () => import(/* webpackChunkName: "gl-product-quotation" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/product-quotation');
const DecryptedProduct = () => import(/* webpackChunkName: "gl-decrypted-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypted-product');
const QuotedProduct = () => import(/* webpackChunkName: "gl-quoted-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/quoted-product');
const QuotingProduct = () => import(/* webpackChunkName: "gl-quoting-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/quoting-product');
// const Decrypt = () => import(/* webpackChunkName: "gl-prod-quote-decrypt" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/prod-quote-decrypt');
const Chosen = () => import(/* webpackChunkName: "gl-chosen" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/chosen');

const routes = [
    {
        path: 'quotation-manage',
        component: View,
        meta: {
            title: '报价管理'
        },
        children: [
            {
                path: 'product-quotation',
                component: ProductQuotation,
                meta: {
                    title: '产品报价查询'
                }
            },
            {
                path: 'product-quotation/quoting-product',
                component: QuotingProduct,
                meta: {
                    title: '可报价产品数',
                    display: 'none'
                }
            },
            {
                path: 'product-quotation/quoted-product',
                component: QuotedProduct,
                meta: {
                    title: '已报价产品数',
                    display: 'none'
                }
            },
            {
                path: 'decrypt/decrypted-product',
                component: DecryptedProduct,
                meta: {
                    title: '已解密产品数',
                    display: 'none'
                }
            },
            {
                path: 'decrypt2/decrypted-product2',
                component: () => import(/* webpackChunkName: "gl-decrypted-product2" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypted-product2'),
                meta: {
                    title: '已解密产品数',
                    display: 'none'
                }
            },
            {
                path: 'decrypt',
                component: () => import(/* webpackChunkName: "decrypt" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypt'),
                meta: {
                    title: '报价解密-已开始'
                    // display: 'none'
                }
                // 解密状态，如果未完成就进入步骤页面，完成就进入统计页面
                // beforeEnter: (to, from, next) => {
                //     fetch({
                //         url: '/hxupsc/api/admin/quote/statistics',
                //         params: {
                //             round: 1,
                //             projectId: to.query.itemId || 1
                //         }
                //     }).then(res => {
                //         console.log('fetch', res);
                //         if (res.data && res.data.complete) {
                //             // 已解密产品数
                //             next({
                //                 path: '/hxupsc/manage-org/proj-inner/quotation-manage/decrypt/decrypted-product',
                //                 query: {
                //                     ...from.query,
                //                     round: 1
                //                 }
                //             });
                //         } else {
                //             next();
                //         }
                //     }, () => {
                //         next();
                //     });
                // }
            },
            // decrypted-statistical
            {
                path: 'decrypted-statistical',
                component: () => import(/* webpackChunkName: "gl-decrypted-statistical" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypted-statistical'),
                meta: {
                    title: '首轮报价解密结果'
                }
            },
            {
                path: 'decrypted-statistical2',
                component: () => import(/* webpackChunkName: "gl-decrypted-statistical2" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypted-statistical2'),
                meta: {
                    title: '二轮报价加解密统计'
                }
            },
            {
                path: 'decrypt2',
                component: () => import(/* webpackChunkName: "decrypt2" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypt2'),
                meta: {
                    title: '二次报价解密'
                    // display: 'none'
                }
                // 解密状态，如果未完成就进入步骤页面，完成就进入统计页面
                // beforeEnter: (to, from, next) => {
                //     fetch({
                //         url: '/hxupsc/api/admin/quote/statistics',
                //         params: {
                //             round: 2,
                //             projectId: to.query.itemId || 1
                //         }
                //     }).then(res => {
                //         console.log('fetch', res);
                //         if (res.data && res.data.complete) {
                //             // 已解密产品数
                //             next({
                //                 path: '/hxupsc/manage-org/proj-inner/quotation-manage/decrypt2/decrypted-product2',
                //                 query: {
                //                     ...from.query,
                //                     round: 2
                //                 }
                //             });
                //         } else {
                //             next();
                //         }
                //     }, () => {
                //         next();
                //     });
                // }
            },
            {
                path: 'chosen',
                component: Chosen,
                meta: {
                    title: '报价入选管理'
                }
            },
            {
                path: 'price-score-count',
                component: () => import(/* webpackChunkName: "gl-price-score-count" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/price-score-count'),
                meta: {
                    title: '价格分计算'
                }
            },
            {
                path: 'procurement-demand-score',
                component: () => import(/* webpackChunkName: "procurement-demand-score" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/procurement-demand-score'),
                meta: {
                    title: '采购需求量得分'
                }
            },
            {
                path: 'medical-institutions-covered-score',
                component: () => import(/* webpackChunkName: "medical-institutions-covered-score" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/medical-institutions-covered-score'),
                meta: {
                    title: '医药机构覆盖数量得分'
                }
            },
            {
                path: 'quotation-inquiry',
                component: () => import(/* webpackChunkName: "quotation-inquiry" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/quotation-inquiry'),
                meta: {
                    title: '首轮报价查询'
                }
            }
        ]
    }
];

export default routes;
