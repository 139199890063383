/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-26 11:10:36
 * @Last Modified by: hejk
 * @Last Modified time: 2020-04-13 16:29:54
 * @Description: 单一规格
 */
<template>
    <div>
        <bk-list ref="table"
                 :table-config="tableConfig"
                 :search-config="searchConfig" />
    </div>
</template>
<script>
import common from '@/api/aup/common';

export default {
    data() {
        const search = common.getSearch('编号,规格,型号');
        const table = common.getCol('编号,规格,型号,备注');
        const { purPlatformProdCode, projectId } = this.$route.query;
        return {
            tableConfig: {
                http: {
                    url: '/hxupsc/api/prod/enroll/single/standard/pageSingleStandard',
                    method: 'get',
                    params: {
                        purPlatformProdCode,
                        projectId
                    }
                },
                selection: false,
                data: table
            },
            searchConfig: {
                layout: 'flow',
                data: [
                    {
                        data: search
                    }
                ]
            }
        };
    }
};
</script>
